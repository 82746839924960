import React from 'react';

export const UnderConstruction = () => {
	return (
		<div className="exception-body underConstruction">
			<div className="exception-text">
				<div className="notfound-box">
					<span>503</span>
				</div>
				<span className="notfound-text">Under Construction</span>
			</div>
			<div className="exception-panel">
				<div className="exception-panel-content">
					<div className="information-text">
						<h3>Grab some Primantis and check back later</h3>
						<p><i>It&#39;s between Councourses B and C</i></p>
					</div>
				</div>
			</div>
		</div>
	);
}
