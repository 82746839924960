import React, { useRef, useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AcaaMap } from '../components/AcaaMap';
import { nullDashTemplate } from '../utilities/ColumnUtil';
import { Form, Field } from 'react-final-form';
import { validateLatCoord } from '../utilities/ValidationHelper';
import { validateLngCoord } from '../utilities/ValidationHelper';
import { Message } from 'primereact/message';
import AppSettings from '../appsettings.json';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = AppSettings.MapboxToken;

const loadFail = "Failed to load location sensor list.";
const submitMsg = "New coordinates submitted.";
const deletedMsg = "Coordinates have been sucessfully unset.";

const failMsg = "Failed to submit coordinates. Please try again.";
const midCoords = {
	lat: -80.2463,
	lng: 40.4947
}

/*
	Coordinates are received from the DB as 'long' field. Places and rowData will give the value
	in the 'long' attribute rather than the 'lng' attribute. Be careful when changing coords
	or when submitting for put/post
*/
export const LocationSelect = () => {
	const [locations, setLocations] = useState([]);
	const [latStr, setLatStr] = useState("0.0");
	const [lngStr, setLngStr] = useState("0.0");
	const [latCoord, setLatCoord] = useState('0.0');
	const [lngCoord, setLngCoord] = useState('0.0');
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [popup, setPopup] = useState(new mapboxgl.Popup({ focusAfterOpen: false }));
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const user = useContext(UserContext);
	const map = useRef(null);

	useEffect(() => {
		user.apiCall(`place/coords`, "GET")
			.then((res) => {
				if (res.status !== 200) {
					user.showErrorToast(loadFail);
					return;
				}

				setLocations(res.data);
			})
			.catch((ex) => {
				user.showErrorToast(loadFail);
				console.error(ex);
			})

		map.current.on('click', (e) => {
			const lat = e.lngLat.lat.toFixed(6);
			const lng = e.lngLat.lng.toFixed(6);
			setLatStr(lat);
			setLngStr(lng);
			setLatCoord(parseFloat(lat));
			setLngCoord(parseFloat(lng));
			setIsSubmitDisabled(false);
		});

		// Check layout-main and erase padding
		const holder = document.getElementsByClassName("layout-main");
		holder[0].classList.add("erase-padding");

		return () => {
			const holder = document.getElementsByClassName("layout-main");
			holder[0].classList.remove("erase-padding");
		}
	}, [])

	/* Updates from map click AND input text change */
	useEffect(() => {
		let latError = validateLatCoord(latCoord);
		let lngError = validateLngCoord(lngCoord);
		popup.remove();
		if (lngCoord === 0.0 || latCoord === 0.0 || selectedPlace === null) {
			return;
		}

		if (latError === undefined && lngError === undefined) {
			const markup = `${selectedPlace.name}<br/>${latCoord}<br/>${lngCoord}`;
			popup.setLngLat({ lng: lngCoord, lat: latCoord })
				.setHTML(markup)
				.addTo(map.current);
		}
	}, [latCoord, lngCoord, selectedPlace])

	const showOnMap = ({ data, index, originalEvent }) => {

		setSelectedPlace(data);

		if (data.long !== null && data.lat !== null) {
			setLatStr(data.lat);
			setLngStr(data.long);
			setLatCoord(data.lat);
			setLngCoord(data.long);

			map.current.flyTo({ center: [data.long, data.lat], zoom: 16.5 });
		}
		else {
			setLatCoord(midCoords.lat);
			setLngCoord(midCoords.lng);
		}
	}

	const handleLatChange = (value) => {
		setLatStr(value);
		setIsSubmitDisabled(false);

		if (value == "" || value == null) {
			setLatCoord(null);
		} else {
			let parsed = parseFloat(value);

			setLatCoord(parseFloat(parsed.toFixed(6)));

		}
	}

	const handleLngChange = (value) => {
		setLngStr(value);
		setIsSubmitDisabled(false);
		if (value == "" || value == null) {
			setLngCoord(null);
		}
		else {
			let parsed = parseFloat(value);
			setLngCoord(parseFloat(parsed.toFixed(6)));

		}
	}


	const submitCoordinates = (requestType) => {
		let errorMessages = ["Error:"];

		if (selectedPlace === null) {
			return;
		}
		let requestURL = "place/coords";

		let lngError = validateLngCoord(lngCoord);
		let latError = validateLatCoord(latCoord);

		if (lngError !== undefined) {
			errorMessages.push(lngError);
		}
		if (latError !== undefined) {
			errorMessages.push(latError);
		}

		if (lngError === undefined && latError === undefined) {
			const body = {
				...selectedPlace,
				lat: latCoord,
				long: lngCoord
			};
			let reqType = requestType !== null && requestType !== undefined ? requestType : "PUT";
			if (reqType == "DELETE") {
				requestURL = "place/coords/" + selectedPlace.placeCoordinatesGuid;
			}
			else if (selectedPlace.placeCoordinatesGuid === null) {
				delete body.placeCoordinatesGuid;
				reqType = "POST";
			}
			user.apiCall(requestURL, body, reqType)
				.then((res) => {
					user.showSuccessToast(submitMsg)

					let newData = [...locations];
					const idx = locations.findIndex((val) => val.placeGuid === res.data.placeGuid);
					newData[idx] = res.data;
					setLocations(newData);

					resetToMid();
				})
				.catch(() => user.showErrorToast(failMsg))
		} else {
			user.showErrorToast(errorMessages.join(" "));
		}
	}

	const resetToMid = () => {
		setSelectedPlace(null);
		setLatStr("0.0");
		setLngStr("0.0");
		setLatCoord("0.0");
		setLngCoord("0.0");
		setIsSubmitDisabled(true);
		setShowConfirmDelete(false);
		map.current.flyTo({ center: [midCoords.lat, midCoords.lng], zoom: 15 });
	}

	const zoomToClick = () => {
		let lngError = validateLngCoord(lngStr);
		let latError = validateLatCoord(latStr);

		if (latError !== undefined || lngError !== undefined) {
			user.showErrorToast("Coordinates Invalid");
		}
		else if (map.current) {
			map.current.flyTo({
				center: [lngStr, latStr],
				zoom: 15
			});
		} else {
			console.error('Map reference is not initialized.');
		}
	};

	const content = (
		<div className="flex align-items-center">
			<div className="ml-2">THIS LOCATION IS UNSET.</div>
		</div>
	);

	const resetTemplate = (rowData, options) => {
		return rowData.lat && rowData.long ?
			<Button type="button" icon='pi pi-times' className="p-button-sm p-button-text" onClick={() => { setShowConfirmDelete(true); showOnMap({ data: rowData }, true) }} />
			: null;
	};

	/* Wrap in span so absolute divs on left slide with sidebar */
	return (
		<span id="LocationSelect">
			<AcaaMap mapRef={map} />
			<div className={`overlay loc-list ${selectedPlace !== null ? "closed" : ""}`}>
				<DataTable value={locations}
					size="small"
					onRowClick={showOnMap}
					responsiveLayout="scroll"
					filterDisplay="row"
					rowHover
				>
					<Column field="name"
						header="Name"
						showFilterMenu={false}
						filter
						filterMatchMode="contains"
					/>
					<Column field="lat"
						header="Lat"
						body={(d) => nullDashTemplate(d, "lat")}
					/>
					<Column field="long"
						header="Lon"
						body={(d) => nullDashTemplate(d, "long")}
					/>
					<Column header="Unset" style={{ flex: '0 0 4rem' }} body={resetTemplate}></Column>
				</DataTable>
			</div>
			<div className={`overlay lat-lng ${selectedPlace === null ? "closed" : ""}`}>
				<h2>{selectedPlace?.name ?? "[PLACE]"}</h2>
				{showConfirmDelete ?
					<div className="padding-standard unset-coord-text">
						<h3>Unsetting Coordinates!</h3>
						Are you sure you want to unset the coordinates for this place?<br></br>
						This will remove the lat and long values.
					</div>
					: <><div className="p-d-flex">
						<div className="p-mr-4">
							<span className="p-float-label">
								<InputText value={latStr}
									keyfilter={/^[0-9.-]+$/}
									onChange={(e) => handleLatChange(e.target.value, "lat")}
								/>
								<label htmlFor='year'>Latitude</label>
							</span>
						</div>
						<div className="p-mr-4">
							<span className="p-float-label">
								<InputText value={lngStr}
									keyfilter={/^[0-9.-]+$/}
									onChange={(e) => handleLngChange(e.target.value, "lng")}
									invalid={(lngStr < -180 || lngStr > 180) ? "true" : "false"}
								/>
								<label htmlFor='year'>Longitude</label>
							</span>
						</div>

						<div className="p-mr-4">

							<Button label="Zoom"
								onClick={() => zoomToClick()}
							/>
							<Button label="Reset"
								onClick={() => { handleLatChange(selectedPlace?.lat == null ? "0.0" : selectedPlace.lat); handleLngChange(selectedPlace?.long == null ? "0.0" : selectedPlace.long); }}
							/>
						</div>
					</div>
						<div className="">
							<p>** Manual changes will only modify up to the first 6 decimal places</p>
						</div>	</>}

				<div className="p-d-flex">
					{selectedPlace !== null && selectedPlace.lat === null && selectedPlace.long === null && (
						<div className="card ">
							<Message
								style={{
									border: 'solid #cc8925',
									borderWidth: '0 0 0 6px',
									color: '#cc8925'
								}}
								className="border-primary w-full justify-content-start"
								severity="warn"
								content={content}
							/></div>
						// <p style={{ color: "#FF0000" }}>THIS LOCATION IS UNSET</p>
					)}
				</div>
				<Button label={showConfirmDelete ? "Confirm" : "Submit"}
					onClick={showConfirmDelete ? () => submitCoordinates("DELETE") : () => submitCoordinates()}
					disabled={showConfirmDelete ? false : isSubmitDisabled}
				/>
				<Button label="Cancel" onClick={() => resetToMid()} />
			</div>

		</span>
	);
}
