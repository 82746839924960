import React, { useEffect, useState, useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from "primereact/fileupload";
import { getFormErrorMessage } from '../utilities/FormHelpers';
import { UserContext } from '../UserContext.js';

const fullSizeField = {
	marginLeft: "1%",
	marginRight: "1%",
	display: "inline-block",
	verticalAlign: "top",
	width: "48%"
}

/*
    props:
    values {}
    onSubmit (data)
*/
export const AssetForm = (props) => {
	const user = useContext(UserContext)
	const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
	const [assetDepartmentOptions, setAssetDepartmentOptions] = useState([]);
	const [fuelTypeOptions, setFuelTypeOptions] = useState([]);

	useEffect(() => {
		user.apiCall(`assetformoptions`)
			.then((res) => {
				setVehicleTypeOptions(res.data.vehicleTypes);
				setAssetDepartmentOptions(res.data.assetDepartments);
				setFuelTypeOptions(res.data.fuelTypes);
			})
	}, [])

	const validate = (formData) => {
		const numsOnlyRegex = new RegExp("[^0-9]");
		let errors = {};

		if(!formData.assetSerial || formData.assetSerial === "") {
			errors.assetSerial = "Serial required";
		}

		if(!formData.year || numsOnlyRegex.test(formData.year)) {
			errors.year = "Year required";
		}

		if(!formData.make || formData.make === "") {
			errors.make = "Make required";
		}

		if(!formData.model || formData.model === "") {
			errors.model = "Model required";
		}

		if(!formData.serialNumber || formData.serialNumber === "") {
			errors.serialNumber = "Serial required";
		}

		if(!formData.jdeNumber || formData.jdeNumber === "") {
			errors.jdeNumber = "JDE number required";
		}

		if(!formData.fuelTypeGuid || formData.fuelTypeGuid === "") {
			errors.fuelTypeGuid = "Fuel type required";
		}

		if(!formData.assetDepartmentGuid || formData.assetDepartmentGuid === "") {
			errors.assetDepartmentGuid = "Department required";
		}

		if(!formData.vehicleTypeGuid || formData.vehicleTypeGuid === "") {
			errors.vehicleTypeGuid = "Vehicle type required";
		}

		return errors;
	}

	const handleUpload = (imageData) => {
		let body = new FormData();
		imageData.files.forEach((file) => body.append(props.values.assetSerial, file))

		user.apiCall("assetupload", body, "POST")
			.then((res) => {
				props.values.imageUrl = res.data;
				// Send in full data to get new "timestamped" imageUrl
				// to force image blob fetch instead of cache fetch
				props.onSubmit(props.values);
			})
			.catch((res) => console.log(res));
	}

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit, values}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<div>
						<Field name="assetSerial"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="assetSerial"
										/>
										<label htmlFor='assetSerial'>Asset Serial:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="year"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="year"
										/>
										<label htmlFor='year'>Year:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
					</div>
					<div>
						<Field name="make"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="make"
										/>
										<label htmlFor='make'>Make:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="model"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="model"
										/>
										<label htmlFor='model'>Model:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
					</div>
					<div>
						<Field name="serialNumber"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="serialNumber"
										/>
										<label htmlFor='serialNumber'>Serial Number:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="jdeNumber"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<InputText {...input}
											id="jdeNumber"
										/>
										<label htmlFor='jdeNumber'>JDE Number:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
					</div>
					<div>
						<Field name="fuelTypeGuid"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<Dropdown {...input}
											id="fuelTypeGuid"
											options={fuelTypeOptions}
											optionLabel="label"
											style={{minWidth: '12rem'}}
										/>
										<label htmlFor='fuelTypeGuid'>Fuel Type:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="vehicleTypeGuid"
							render={({ input, meta }) => (
								<div style={fullSizeField}>
									<span className="p-float-label">
										<Dropdown {...input}
											id="vehicleTypeGuid"
											options={vehicleTypeOptions}
											optionLabel="label"
											style={{minWidth: '12rem'}}
										/>
										<label htmlFor='vehicleTypeGuid'>Vehicle Type:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
					</div>
					<Field name="assetDepartmentGuid"
						render={({ input, meta }) => (
							<div style={fullSizeField}>
								<span className="p-float-label">
									<Dropdown {...input}
										id="assetDepartmentGuid"
										options={assetDepartmentOptions}
										optionLabel="label"
										style={{minWidth: '12rem'}}
									/>
									<label htmlFor='assetDepartmentGuid'>Department:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="sensorMountDesc"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputTextarea {...input}
										rows='2'
										cols='50'
										id="sensorMountDesc"
									/>
									<label htmlFor='sensorMountDesc'>Mount Location:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					{values.assetGuid && (<>
						<div style={fullSizeField}>
							<FileUpload mode="basic"
								name="assetImage"
								accept="image/*"
								url="/assetupload"
								onUpload={(e) => console.log(e)}
								chooseLabel="Upload New Image"
								uploadHandler={handleUpload}
								customUpload
								auto
							/>
						</div>
						<div style={{maxWidth: "50vw", padding: "0 48px"}}>
							<img src={values.imageUrl}
								alt="View of asset"
								style={{maxWidth: "100%", maxHeight: "50vh", margin: "auto", display: "block"}}
							/>
						</div>
                           </>)}
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}