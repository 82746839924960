import React from 'react';
import splash from '../img/dashboard_splash.jpg';

export const Dashboard = () => {
	return (
		<div id="Dashboard">
			<h1>Welcome to the ACAA Admin App</h1>
			<img src={splash} alt="PIT airport splash image" />
		</div>
	);
}