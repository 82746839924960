import React from "react";
import { format } from "date-fns";

export const booleanTemplate = (data, field) => {
	const nestedValue = getNestedValue(data, field);
	const icon = nestedValue ? "circle" : "times";
	return (
		<i className={`boolean pi pi-${icon}`} />
	)
}

export const utcToLocalTemplate = (data, field) => {
	const nestedValue = getNestedValue(data, field);
	if(!nestedValue) {
		return (<></>);
	}

	const dateDisplay = Date.parse(nestedValue);
	let output = "--";
	if(format(dateDisplay, "yyyy") !== "0001" && format(dateDisplay, "yyyy") !== "3000") {
		output = format(dateDisplay, "M/d/yyyy h:mm") + "\xa0" + format(dateDisplay, "a");
	}
	return (
		<>{output}</>
	)
}

export const nullDashTemplate = (data, field) => {
	const nestedValue = getNestedValue(data, field);
	return (
		<>{nestedValue ?? "--"}</>
	)
}

const getNestedValue = (data, field) => {
	const nestedField = field.split(".");
	let nestedValue = data;
	nestedField.forEach((val) => {
		nestedValue = nestedValue[val];
	});
	return nestedValue;
}