import React, { useState, useEffect, useRef } from "react";
import AppSettings from '../appsettings.json';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = AppSettings.MapboxToken;
import './MapComponent.css';

/*********************************************************************
 * 		!!!!!!!!	THIS COMPONENT IS OUTDATED		!!!!!!!!
 * 		!!!!!!!!		NEEDS REWORK				!!!!!!!!
 ***********************************************************************/
export const DeviceMap = (props) => {
	const mapContainer = useRef(null);
	const [mapGlobal, setMapGlobal] = useState(null);
	/*if(document.getElementById('deviceTableMap')){
        const resizeObserver = new ResizeObserver(() => {
            console.log('triggered'+mapGlobal)
            if(mapGlobal){
                mapGlobal.resize();
            }
        });
        resizeObserver.observe(document.getElementById('deviceTableMap'));
    }*/

	useEffect(() => {

		const map = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/light-v10',
			center: [props.lon, props.lat],
			zoom: 15.4
		});
		map.on("load", () => {
			new mapboxgl.Marker()
				.setLngLat([props.lon, props.lat])
				.addTo(map);
		})

		setMapGlobal(map);

	}, []);

	return (
		<div id="map"
			style={{height: '400px',width: '100%'}}
			className="map-container p-shadow-3"
			ref={mapContainer} />
	);
};
