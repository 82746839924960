import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from 'primereact/api';
import { SelectButton } from "primereact/selectbutton";
import { ManufacturerForm } from "../forms/ManufacturerForm";
import { BuildingForm } from "../forms/BuildingForm";
import { LevelForm } from "../forms/LevelForm";
import { SectionForm } from "../forms/SectionForm";
import { PlaceForm } from "../forms/PlaceForm";
import { ModelForm } from "../forms/ModelForm";
import { ModelTypeForm } from "../forms/ModelTypeForm";
import { SourceForm } from "../forms/SourceForm";
import { PlaceTypeForm } from "../forms/PlaceTypeForm";
import { PlaceTypeCatForm } from "../forms/PlaceTypeCatForm";
import { SensorCatForm } from "../forms/SensorCatForm";
import { AssetDepartmentForm } from "../forms/AssetDepartmentForm"
import { FuelTypeForm } from "../forms/FuelTypeForm";
import { VehicleCategoryForm } from "../forms/VehicleCategoryForm";
import { VehicleTypeForm } from "../forms/VehicleTypeForm";
import { AssetForm } from "../forms/AssetForm";
import { booleanTemplate } from "../utilities/ColumnUtil";
import tabOptions from "../utilities/MasterCrudOptions.json";

const tableLoadFail = "Selected metadata table failed to load.";

export const MasterCrud = () => {
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState([]);
	const [filters, setFilters] = useState({})
	const [dataKey, setDataKey] = useState("");
	const [tableName, setTableName] = useState("");
	const [selectedEntry, setSelectedEntry] = useState({});
	const [showForm, setShowForm] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [title, setTitle] = useState("");
	const [selectedTab, setSelectedTab] = useState("");

	const user = useContext(UserContext);

	useEffect(() => {
		if(tableName === ""){
			return;
		}

		user.apiCall(`${tableName}`)
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast(tableLoadFail);
					return;
				}

				setData(res.data);
			})
			.catch((ex) => {
				user.showErrorToast(ex.response?.data ?? tableLoadFail);
			})
	},[tableName])

	const handleSubmit = (formData) => {
		if(formData[dataKey] !== null && formData[dataKey] !== undefined) {
			user.apiCall(`${tableName}`, formData, "PUT")
				.then((res) => {
					let newData = data;
					const idx = data.findIndex((val) => val[dataKey] === formData[dataKey]);
					newData[idx] = res.data;
					setData(newData);
					user.showSuccessToast(`${title} entry updated.`);
				})
				.catch((error) => user.showErrorToast(error.response.data))
				.then(() => {
					setShowForm(false);
					setSelectedEntry({});
				})
		}
		else {
			user.apiCall(`${tableName}`, formData, "POST")
				.then((res) => {
					setData([res.data, ...data]);
					user.showSuccessToast(`${title} entry created.`);
				})
				.catch((error) => user.showErrorToast(error.response.data))
				.then(() => {
					setShowForm(false);
					setSelectedEntry({});
				})
		}
	}

	const handleDelete = () => {
		user.apiCall(`${tableName}/${selectedEntry[dataKey]}`, {}, "DELETE")
			.then((res) => {
				const idx = data.findIndex((val) => val[dataKey] === selectedEntry[dataKey]);
				let newData = data;
				newData.splice(idx, 1);
				setData(newData);
				user.showSuccessToast(`${title} entry deleted.`);
			})
			.catch((error) => user.showErrorToast(error.response.data))
			.then(() => {
				setShowDelete(false);
				setSelectedEntry({});
			})
	}

	const selectTab = (option) => {
		if(option!=null){
			setData([]);
			setColumns(tabOptions[option].columns);
			setDataKey(tabOptions[option].dataKey);
			setTableName(tabOptions[option].tableName);
			setTitle(tabOptions[option].title);
			setSelectedTab(option)

			let newFilters = {};
			tabOptions[option].columns.forEach((val) => {
				newFilters[val.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
			})
			setFilters(newFilters);
		}
	}

	const openModal = (entry) => {
		setShowForm(true);
		setSelectedEntry(entry);
	}

	const openDeleteModal = (entry) => {
		setShowDelete(true);
		setSelectedEntry(entry);
	}

	const closeDeleteModal = () => {
		setShowDelete(false);
		setSelectedEntry({});
	}

	const leftToolbar = () => {
		const keys = Object.keys(tabOptions).map((val) => {
			return {
				label: tabOptions[val].title,
				value: val
			};
		});

		return (
			<>
				<div>
					<SelectButton value={selectedTab}
						options={keys.slice(0, 6)}
						onChange={(e) => selectTab(e.value)}
						key="selectBase"
					/>
					<SelectButton value={selectedTab}
						options={keys.slice(6, 9)}
						onChange={(e) => selectTab(e.value)}
						key="selectSensor"
					/>
				</div>
				<div>
					<SelectButton value={selectedTab}
						options={keys.slice(9, 11)}
						onChange={(e) => selectTab(e.value)}
						key="selectSource"
					/>
					<SelectButton value={selectedTab}
						options={keys.slice(11)}
						onChange={(e) => selectTab(e.value)}
						key="selectAsset"
					/>
				</div>
			</>
		);
	}

	const actionTemplate = (rowData) => (
		<div>
			<Button icon="pi pi-pencil"
				className="p-button-raised p-button-text edit"
				onClick={() => openModal(rowData)}
			>
				Edit
			</Button>
			<Button icon="pi pi-trash"
				className="p-button-raised p-button-text delete"
				onClick={() => openDeleteModal(rowData)}
			>
				Delete
			</Button>
		</div>
	);

	const actionHeader = () => (
		<Button icon="pi pi-plus"
			className="p-button-raised p-button-text new"
			onClick={() => openModal({})}
		>
			New
		</Button>
	);

	return (
		<div id="MasterCrud">
			<h1>{title}</h1>
			<div>
				<Toolbar left={leftToolbar} />
				<DataTable value={data}
					dataKey={dataKey}
					emptyMessage={dataKey === "" ?
						"Click a button in the toolbar to load data" :
						"No records found"}
					filters={filters}
					filterDisplay="row"
					onFilter={(e) => setFilters(e.filters)}
					rows={10}
					rowsPerPageOptions={[10, 25]}
					paginator
					rowHover
				>
					{columns.map((val, idx) =>
						(<Column key={idx}
							field={val.field}
							header={val.header}
							showFilterMenu={false}
							body={val.body === "bool" ? (data) => booleanTemplate(data, val.field) : null}
							filter
							sortable
						/>)
					)}
					{tableName !== "" && (
						<Column field=""
							header={actionHeader}
							body={actionTemplate}
							className="action-col"
							style={{ width: "200px" }}
						/>
					)}
				</DataTable>
				<Dialog key="forms"
					visible={showForm}
					header={`${title} Management`}
					onHide={() => {
						setShowForm(false); setSelectedEntry({});
					}}
					style={{minWidth: '30vw'}}
					draggable={false}
					resizable={false}
					dismissableMask
				>
					{tableName === "manufacturer" && (
						<ManufacturerForm key="manufacturer"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "modeltype" && (
						<ModelTypeForm key="modeltype"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "building" && (
						<BuildingForm key="building"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "level" && (
						<LevelForm key="level"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "section" && (
						<SectionForm key="section"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "place" && (
						<PlaceForm key="place"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "model" && (
						<ModelForm key="model"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "source" && (
						<SourceForm key="source"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "sensorcat" && (
						<SensorCatForm key="sensorcat"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "placetypecat" && (
						<PlaceTypeCatForm key="placetypecat"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "placetype" && (
						<PlaceTypeForm key="placetype"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "assetdepartment" && (
						<AssetDepartmentForm key="assetdepartment"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "fueltype" && (
						<FuelTypeForm key="fueltype"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "vehiclecategory" && (
						<VehicleCategoryForm key="vehiclecategory"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "vehicletype" && (
						<VehicleTypeForm key="vehicletype"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
					{tableName === "asset" && (
						<AssetForm key="asset"
							values={selectedEntry}
							onSubmit={handleSubmit} />
					)}
				</Dialog>
				<Dialog key="delete"
					visible={showDelete}
					header={`${title} Delete`}
					onHide={() => closeDeleteModal()}
					style={{minWidth: '20vw'}}
					className="master-crud-delete"
					draggable={false}
					resizable={false}
					dismissableMask
				>
					Are you sure you wish to delete this {title}?
					{Object.keys(selectedEntry).map((val, idx) => {
						//I can't believe this worked. $5 on it breaking soon
						if(val.toLowerCase() === `${tableName}name`.toLowerCase()) {
							return (
								<div key={idx} className="delete-entry">
									{selectedEntry[val]}
								</div>
							)
						}
						return (<></>)
					})}
					<div className='cancel-footer'>
						<Button label="Confirm" onClick={() => handleDelete()} />
						<Button label="Cancel" onClick={() => closeDeleteModal()} />
					</div>
				</Dialog>
			</div>
		</div>
	);
}