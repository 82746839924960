import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import { createRoot } from "react-dom/client";
import { MsalWrapper } from "./auth/MsalWrapper";

//import * as serviceWorker from './serviceWorker';
const root = createRoot(document.getElementById('root'));

root.render(
	<HashRouter>
		<MsalWrapper>
			<AppWrapper />
		</MsalWrapper>
	</HashRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
