import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from 'primereact/api';
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from 'primereact/card';

const globalFilters = [
    "global", "tableName", "storageName", "latestTimeStamp", "isEmpty"
];
const exportColumns = [
    {title:"Storage Name", dataKey:"storageName"},
    {title:"Table Name", dataKey:"tableName"},
    {title:"Latest Timestamp", dataKey:"latestTimeStamp"},
    {title:"Table Empty", dataKey:"isEmpty"}
];
const dropdownOptions = [
    { label: "Stacaa", value: "Stacaa" },
    //{ label: "Production", value: "Production" },
    { label: "All Tables", value: "All Tables" }
];
const initFilters = () => {
    return {
        "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "sensorUid": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "tableName": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "storageName": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "latestTimeStamp": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "isEmpty": { value: null, matchMode: FilterMatchMode.CONTAINS },
    }
};
const loadFailure = "Table data failed to load";

export const AztDiagnostics = () => {
    const [dataLoading, setDataLoading] = useState(false);
    const [baseFilters, setBaseFilters] = useState(initFilters);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [tables, setTables] = useState([]);
    const [filterTables, setFilterTables] = useState([]);
    const [selectedStorageType, setSelectedStorageType] = useState("Stacaa");
    const [stagnateCount, setStagnateCount] = useState(0); // State for stagnate count
    const [normalCount, setNormalCount] = useState(0); // State for normal count

    const user = useContext(UserContext);

    useEffect(() => {
        let apiUrl = "aztdiagnostics";
        if(selectedStorageType === "Stacaa"){
            apiUrl += "/stacaa";
        }
        user.apiCall(apiUrl, "GET")
            .then((res) => {
                if(res.status !== 200) {
                    user.showErrorToast(loadFailure);
                    return;
                }
                console.log(res.data)
                setTables(res.data.tables)
                setFilterTables(res.data.tables);
                setDataLoading(false);
                // Calculate stagnate and normal counts
                const counts = calculateCounts(res.data.tables);
                setStagnateCount(counts.stagnateCount);
                setNormalCount(counts.normalCount);
            })
            .catch(() => {
                user.showErrorToast(loadFailure);
                setDataLoading(false);
            })
    }, [selectedStorageType])

    const calculateCounts = (data) => {
        let stagnate = 0;
        let normal = 0;
        data.forEach(table => {
            const eventDateTime = new Date(table.latestTimeStamp);
            const currentTime = new Date();
            const timeDifferenceDays = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60 * 24));
            if (timeDifferenceDays > 1) {
                stagnate++;
            } else {
                normal++;
            }
        });
        return { stagnateCount: stagnate, normalCount: normal };
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...baseFilters };
        _filters["global"].value = value;

        setBaseFilters(_filters);
        setGlobalFilterValue(value);
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName  + new Date() + EXCEL_EXTENSION);
            }
        });
    };
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const filteredData = tables.map(row => {
                const rowData = {};
                exportColumns.forEach(column => {
                    rowData[column.title] = row[column.dataKey];
                });
                return rowData;
            });
            const worksheet = xlsx.utils.json_to_sheet(filteredData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });
            saveAsExcelFile(excelBuffer, 'AztDiagnostics');
        });
    };

    const tableHeader = () => (
        <div>
            <div className="header-cards">
                <Card 
                    title={normalCount > 0 ? String(normalCount) : "--"} 
                    subTitle="Normal Tables" 
                    className="normal card-hover" 
                    onClick={() => filterTableData("normal")}
                />
                <Card 
                    title={stagnateCount > 0 ? String(stagnateCount) : "--"} 
                    subTitle="Stagnate Tables" 
                    className="stagnate card-hover" 
                    onClick={() => filterTableData("stagnate")}
                />
                <Card 
                    title={stagnateCount + normalCount > 0 ? String(normalCount + stagnateCount) : "--"} 
                    subTitle="All Tables" 
                    className="all card-hover" 
                    onClick={() => filterTableData("all")}
                />
            </div>
			<Dropdown value={selectedStorageType} onChange={(e) => setSelectedStorageType(e.value)} placeholder="Select a Storage type" options={dropdownOptions} />
        </div>
    );

    const filterTableData = (filterType) => {
        let filteredData = [];

        if (filterType === "normal") {
            filteredData = tables.filter(table => {
                const daysDifference = Math.floor((Date.now() - new Date(table.latestTimeStamp).getTime()) / (1000 * 60 * 60 * 24));
                console.log(table.latestTimeStamp);
                return daysDifference <= 1;
            });
        } else if (filterType === "stagnate") {
            filteredData = tables.filter(table => {
                const daysDifference = Math.floor((Date.now() - new Date((table.latestTimeStamp)).getTime()) / (1000 * 60 * 60 * 24));
                return daysDifference > 1;
            });
        } else {
            filteredData = tables;
        }

        setFilterTables(filteredData);
    };

    const upperCaseTemplate = (rowData) => {
        const empty = rowData.isEmpty ? "True" : "False";
        return (
            <span>{empty}</span>
        )
    }

    const statusBodyTemplate = (rowData) => {
        let statCss = "";
        let ago = "";
        if (rowData.latestTimeStamp) {
            const eventDateTime = new Date(rowData.latestTimeStamp);
            const currentTime = new Date();
            const timeDifferenceDays = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60 * 24));
            if (timeDifferenceDays > 1) {
                statCss = "stagnate";
            } else {
                statCss = "normal";
            }

            if (timeDifferenceDays > 365) {
                const timeDifferenceYears = Math.floor(timeDifferenceDays / 365);
                ago = timeDifferenceYears == 1?timeDifferenceYears + " Year ago":timeDifferenceYears + " Years ago";
            } else if (timeDifferenceDays > 31) {
                const timeDifferenceMonths = Math.floor(timeDifferenceDays / 30);
                ago = timeDifferenceMonths == 1?timeDifferenceMonths + " Month ago":timeDifferenceMonths + " Months ago";
            } else if (timeDifferenceDays < 1) {
                const timeDifferenceHours = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60));
                if (timeDifferenceHours < 1) {
                    const timeDifferenceMinutes = Math.floor((currentTime - eventDateTime) / (1000 * 60));
                    ago = timeDifferenceMinutes == 1?timeDifferenceMinutes + " Minute ago":timeDifferenceMinutes + " Minutes ago";
                } else {
                    ago = timeDifferenceHours == 1?timeDifferenceHours + " Hour ago":timeDifferenceHours + " Hours ago";
                }
            } else {
                ago = timeDifferenceDays == 1?timeDifferenceDays + " Day ago":timeDifferenceDays + " Days ago";
            }
        }
        return (
            <div className="statusTable">
                <span className={statCss} >
                    {ago}
                </span>
            </div>
        );
    }

    return (
        <div id="AztDiagnostics" className="card">
            <DataTable value={filterTables}
                dataKey="rowKey"
                size="small"
                header={tableHeader}
                filterDisplay="row"
                globalFilterFields={globalFilters}
                rows={18}
                sortField="latestTimeStamp"
                sortOrder={1}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Tables"
                responsiveLayout="scroll"
                paginator
                showGridlines

                className="custom-datatable"
            >
                <Column field="storageName"
                    header="Storage Name"
                    style={{ minWidth: '10rem' }}
                    showFilterMenu={false}
                    align={"center"}
                    filter
                    sortable
                />
                <Column field="tableName"
                    header="Table Name"
                    style={{ minWidth: '22rem' }}
                    showFilterMenu={false}
                    sortable
                    align={"center"}
                    filter
                />
                <Column field="isEmpty"
                    header="Table Empty"
                    style={{ minWidth: '10rem' }}
                    showFilterMenu={false}
                    filter
                    align={"center"}
                    body={upperCaseTemplate}
                    sortable
                />
                <Column field="latestTimeStamp"
                    header="Latest Timestamp"
                    style={{ minWidth: '10rem' }}
                    align={"center"}
                    showFilterMenu={false}
                    body={statusBodyTemplate}
                    sortable
                />

            </DataTable>
        </div>
    );
};