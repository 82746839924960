import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from 'primereact/api';
import { NewSensorForm } from "../forms/NewSensorForm";
import { DropdownFilterTemplate } from "../utilities/FilterUtil";
import { ProgressSpinner } from "primereact/progressspinner";

const globalFilters = [
	"global", "sensorUid", "modelName", "modelTypeName", "airportIataCode", "buildingName", "levelName", "sectionName", "placeName"
];
const initFilters = () => {
	return {
		"global": { value: null, matchMode: FilterMatchMode.CONTAINS },
		"sensorUid": { value: null, matchMode: FilterMatchMode.CONTAINS },
		"modelName": { value: null, matchMode: FilterMatchMode.EQUALS },
		"modelTypeName": { value: null, matchMode: FilterMatchMode.EQUALS },
		"airportIataCode": { value: null, matchMode: FilterMatchMode.EQUALS },
		"buildingName": { value: null, matchMode: FilterMatchMode.EQUALS },
		"levelName": { value: null, matchMode: FilterMatchMode.EQUALS },
		"sectionName": { value: null, matchMode: FilterMatchMode.EQUALS },
		"placeName": { value: null, matchMode: FilterMatchMode.CONTAINS },
	}
};
const orphanFilter = {
	"rowKey": { value: null, matchMode: FilterMatchMode.CONTAINS },
}

const loadFailure = "Device data failed to load";

export const DeviceList = () => {
	const [dataLoading, setDataLoading] = useState(false);
	const [devices, setDevices] = useState([]);
	const [baseFilters, setBaseFilters] = useState(initFilters);
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [modelFilters, setModelFilters] = useState([]);
	const [modelTypeFilters, setModelTypeFilters] = useState([]);
	const [airportFilters, setAirportFilters] = useState([]);
	const [buildingFilters, setBuildingFilters] = useState([]);
	const [levelFilters, setLevelFilters] = useState([]);
	const [sectionFilters, setSectionFilters] = useState([]);
	const [selectedOrphan, setSelectedOrphan] = useState({});
	const [orphansList, setOrphansList] = useState("");
	const [newModalOpen, setNewModalOpen] = useState(false);

	const user = useContext(UserContext);
	const navigate = useNavigate();

	useEffect(() => {
		setDataLoading(true);

		user.apiCall(`devicesadmin`, "GET")
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast(loadFailure);
					return;
				}

				setDevices(res.data.sensors);

				setModelFilters(res.data.modelFilterOptions);
				setModelTypeFilters(res.data.modelTypeFilterOptions);
				setAirportFilters(res.data.airportFilterOptions);
				setBuildingFilters(res.data.buildingFilterOptions);
				setLevelFilters(res.data.levelFilterOptions);
				setSectionFilters(res.data.sectionFilterOptions);
				setDataLoading(false);
			})
			.catch(() => {
				user.showErrorToast(loadFailure);
				setDataLoading(false);
			})
	}, [])

	const fetchOrphans = () => {
		user.apiCall(`orphans`, "GET")
			.then((res) => setOrphansList(res.data));
	}

	const handleNewSensorSubmit = (data) => {
		user.apiCall(`orphans`, data, "POST")
			.then((res) => {
				setDevices([res.data.sensor, ...devices])
				closeNewModal();
				user.showSuccessToast(res.data.message);
			})
			.catch((e) => user.showErrorToast(e.response.data))
	}

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...baseFilters };
		_filters["global"].value = value;

		setBaseFilters(_filters);
		setGlobalFilterValue(value);
	}

	const createNew = () => {
		setNewModalOpen(true);
		fetchOrphans();
	}

	const closeNewModal = () => {
		setNewModalOpen(false);
		setSelectedOrphan({});
	}

	const onViewClick = (event) => {
		navigate("/devices/dashboard/" + event.sensorUid);
	}

	const onEditClick = (entry) => {
		navigate("/devices/details/" + entry.sensorUid);
	}

	const tableHeader = () => (
		<>
			<h5>Devices</h5>
			{dataLoading && (
				<ProgressSpinner />
			)}
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText value={globalFilterValue}
					onChange={onGlobalFilterChange}
					placeholder="Search"
				/>
			</span>
		</>
	);

	const actionBodyTemplate = (rowData) => (
		<>
			<Button icon="pi pi-pencil"
				className="p-button-raised p-button-text"
				onClick={() => onEditClick(rowData)}
			>
					Edit
			</Button>
			<Button icon="pi pi-eye"
				className="p-button-raised p-button-text"
				onClick={() => onViewClick(rowData)}
			>
					View
			</Button>
		</>
	);

	const actionHeader = () => (
		<Button icon="pi pi-plus"
			className="p-button-raised p-button-text"
			onClick={createNew}
		>
			New
		</Button>
	);

	const orphanHeader = () => (
		<div className="orphan-header">
			<Button icon="pi pi-plus"
				className="p-button-raised p-button-text new-btn"
				onClick={() => setSelectedOrphan({rowKey: ""})}
			>
				Add Non-Orphan
			</Button>
		</div>
	);

	return (
		<div id="DeviceList">
			<DataTable value={devices}
				dataKey="sensorUid"
				size="small"
				header={tableHeader}
				filterDisplay="row"
				filters={baseFilters}
				globalFilterFields={globalFilters}
				rows={10}
				rowsPerPageOptions={[5, 10, 25]}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Devices"
				responsiveLayout="scroll"
				paginator
			>
				<Column field="sensorUid"
					header="Id"
					style={{minWidth: '10rem'}}
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="modelName"
					header="Model"
					filterElement={(f) => DropdownFilterTemplate(f, modelFilters, "Model")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="modelTypeName"
					header="Model Type"
					filterElement={(f) => DropdownFilterTemplate(f, modelTypeFilters, "Model Type")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="airportIataCode"
					header="Airport"
					filterElement={(f) => DropdownFilterTemplate(f, airportFilters, "Airport")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="buildingName"
					header="Building"
					filterElement={(f) => DropdownFilterTemplate(f, buildingFilters, "Building")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="levelName"
					header="Level"
					filterElement={(f) => DropdownFilterTemplate(f, levelFilters, "Level")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="sectionName"
					header="Section"
					filterElement={(f) => DropdownFilterTemplate(f, sectionFilters, "Section")}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="placeName"
					header="Place"
					style={{ minWidth: '8rem' }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column header="Actions"
					body={actionBodyTemplate}
					filterElement={actionHeader}
					className="action-column"
					showFilterMenu={false}
					filter
				/>
			</DataTable>
			<Dialog visible={newModalOpen}
				header="Add Device"
				onHide={() => closeNewModal()}
				style={{minWidth: '30vw', maxHeight: "80vh"}}
				draggable={false}
				resizable={false}
				dismissableMask
			>
				{(selectedOrphan.rowKey === null || selectedOrphan.rowKey === undefined) && (
					<div style={{marginTop: "16px"}}>
						<DataTable value={orphansList}
							dataKey="rowKey"
							header={orphanHeader}
							filterDisplay="row"
							filters={orphanFilter}
							onRowClick={(d) => setSelectedOrphan(d.data)}
							rowHover
						>
							<Column field="rowKey"
								header="Sensor Uid"
								style={{minWidth: '8rem', cursor: "pointer"}}
								showFilterMenu={false}
								filter
								sortable
							/>
						</DataTable>
					</div>
				)}
				{(selectedOrphan.rowKey !== null && selectedOrphan.rowKey !== undefined) && (
					<NewSensorForm
						onSubmit={handleNewSensorSubmit}
						values={{sensorUid: selectedOrphan.rowKey}}
					/>
				)}
			</Dialog>
		</div>
	);
};
