import React from "react";
import {
    PublicClientApplication, EventType
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalConfig } from "./Authorization.js"

const pca =
    await PublicClientApplication.createPublicClientApplication(msalConfig);
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
    pca.setActiveAccount(pca.getActiveAccount()[0]);
}

pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        pca.setActiveAccount(account);
    }
});

export const MsalWrapper = ({ children }) => {
    return (
        <>
            <MsalProvider instance={pca}>
                {children}
            </MsalProvider>

        </>
    );
};