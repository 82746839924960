import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from 'primereact/card';
export const ConcessionsDiagnostics = ()=>{
    const user = useContext(UserContext);
    const [dataLoading, setDataLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [table2, setTable2] = useState([]);

    useEffect(() => {
        let getunitgatesplacediscrepancy = "getunitgatesplacediscrepancy";
        let gethotmilesightdoorsdiscrepancy= "gethotmilesightdoorsdiscrepancy";
        setDataLoading(true);

        user.apiCall(getunitgatesplacediscrepancy, "GET")
            .then((res) => {
                if (res.status !== 200) {
                    console.log(res.details);
                    user.showErrorToast(loadFailure);
                    setDataLoading(false);
                    return;
                }
                console.log(res.data);
                setTable(res.data);
                setDataLoading(false);
            })
            .catch((exception) => {
                console.log(exception);
                user.showErrorToast("Exception");
                setDataLoading(false);
            });
        user.apiCall(gethotmilesightdoorsdiscrepancy, "GET")
            .then((res) => {
                if (res.status !== 200) {
                    user.showErrorToast(loadFailure);
                    setDataLoading(false);
                    return;
                }
                console.log(res.data);
                setTable2(res.data);
                setDataLoading(false);
            })
            .catch(() => {
                user.showErrorToast(loadFailure);
                setDataLoading(false);
            });
    }, []);
    const tableHeader = (header)=>{
        return(<h3>{header}</h3>)
    }
    return(
        <div id="ConcessionsDiagnostics" className="card">
            <DataTable value={table}
                size="small"
                header={()=>tableHeader('Gates')}
                showGridlines
                style={{paddingBottom:"50px"}}
            >
                <Column field="gateName" header="Gate Name" sortable />
                <Column field="unitId" header="Unit Id" sortable />
            </DataTable>
            <DataTable value={table2}
                showGridlines
                header={()=>tableHeader('Concession Devices')}
            >
                <Column field="deviceId" header="Device Id"  sortable />
                <Column field="eventDateTime" header="Event Date Time"  sortable />
                <Column field="assetData" header="Asset Data"  sortable />
            </DataTable>
        </div>
    )
}