import React from 'react'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getFormErrorMessage } from '../utilities/FormHelpers'

/*
    props:
    values {}
    onSubmit (data)
*/
export const ManufacturerForm = (props) => {
	const validate = (formData) => {
		let errors = {};

		if(!formData.manufacturerName || formData.manufacturerName === "") {
			errors.manufacturerName = "Name required";
		}

		return errors;
	}

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="manufacturerName"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="manufacturerName"
									/>
									<label htmlFor='manufacturerName'>Name:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}