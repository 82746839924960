import React, { useEffect, useState, useContext } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../utilities/FormHelpers'
import { UserContext } from '../UserContext.js';

/*
    props:
    values {}
    onSubmit (data)
*/
export const BuildingForm = (props) => {
	const [airportOptions, setAirportOptions] = useState([]);
	const user = useContext(UserContext)
	useEffect(() => {
		user.apiCall(`buildingformoptions`)
			.then((res) => setAirportOptions(res.data))
	}, [])

	const buildingSubmit = (formData) => {
		// buIDLing vs buILDing
		formData.buidlingName = formData.buildingName;
		props.onSubmit(formData);
	}

	const validate = (formData) => {
		let errors = {};

		if(!formData.buildingName || formData.buildingName === "") {
			errors.buildingName = "Name required";
		}

		if(!formData.airportGuid) {
			errors.airportGuid = "Airport required";
		}

		return errors;
	}

	return (
		<Form
			onSubmit={buildingSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="buildingName"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="buildingName"
									/>
									<label htmlFor='buildingName'>Name:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="airportGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="airportGuid"
										options={airportOptions}
										optionLabel="label"
									/>
									<label htmlFor='airportGuid'>Airport:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}