import React from "react";
import { FileUpload } from "primereact/fileupload";

/*
    imageList
    setImageList
*/
export const DataUrlUploader = (props) => {
	const handleUpload = async (imgData) => {
		let promiseList = [];
		imgData.files.forEach((file) => {
			const prom = new Promise((resolve) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					resolve(reader.result);
				};
				reader.readAsDataURL(file);
			});
			promiseList.push(prom);
		});

		let uploadedImgs = [];
		await Promise.all(promiseList)
			.then((vals) => {
				const keyDt = Date.now();
				uploadedImgs = vals.map((i, idx) => ({
					dataUrl: i,
					keyNum: `${keyDt + idx}`
				}))
			});

		props.setImageList([...props.imageList, ...uploadedImgs]);
		imgData.options.clear();
	}

	return (
		<FileUpload name="assetImage"
			accept="image/*"
			url="/assetupload"
			mode="basic"
			onUpload={(e) => console.log(e)}
			chooseOptions={{label: "Add Image"}}
			uploadHandler={(data) => handleUpload(data)}
			customUpload
			auto
		/>
	)
}