import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { IssueTypeForm } from "../forms/IssueTypeForm";
import { SensorOutageForm } from "../forms/SensorOutageForm";
import { utcToLocalTemplate } from "../utilities/ColumnUtil";

const dateFmtStr = "yyyy-MM-dd HH:mm";
const fetchFailMsg = "Failed to fetch list of device issue data";
const typeSaveFail = "Failed to save new issue type";
const outageSaveFail = "Failed to save device issue";
const deleteFail = "Failed to delete entry";

export const SensorOutage = () => {
	const [outageData, setOutageData] = useState([]);
	const [issueTypeData, setIssueTypeData] = useState([]);
	const [typeTableOpen, setTypeTableOpen] = useState(false);
	const [typeFormOpen, setTypeFormOpen] = useState(null);
	const [outageFormOpen, setOutageFormOpen] = useState(null);
	const [deleteOpen, setDeleteOpen] = useState(null);
	const [deleteTypeOpen, setDeleteTypeOpen] = useState(null);
	const [placeList, setPlaceList] = useState([]);

	const user = useContext(UserContext);

	useEffect(() => {
		fetchData();
	},[])

	const fetchData =  () => {
		// try{
		// 	const res = await user.apiCall("outagepage");
		// 	setOutageData(res.data.outageList);
		// 	setIssueTypeData(res.data.issueTypes);
		// 	setPlaceList(res.data.placeList);
		// } catch (error)
		// {
		// 	console.log(error);
		// 	user.showErrorToast(fetchFailMsg, true);
		// }
		user.apiCall("outagepage")
			.then((res) => {
				console.log(res.data);
				setOutageData(res.data.outageList);
				setIssueTypeData(res.data.issueTypes);
				setPlaceList(res.data.placeList);
			})
			.catch(() => user.showErrorToast(fetchFailMsg, true));
	}

	const handleTypeSubmit = (formData) => {
		let type = "POST";
		if(formData.rowKey !== null && formData.rowKey !== undefined) {
			type = "PUT";
		}

		user.apiCall("outagetypes", formData, type)
			.then(() => {
				setTypeFormOpen(null);
				fetchData();
			})
			.catch(() => user.showErrorToast(typeSaveFail))
	}

	const handleOutageSubmit = (formData) => {
		formData.startDate = formatInTimeZone(formData.startDate, "Etc/UTC", dateFmtStr) + ":00z";
		if(formData.endDate !== null && formData.endDate !== undefined) {
			formData.endDate = formatInTimeZone(formData.endDate, "Etc/UTC", dateFmtStr) + ":00z";
		}
		console.log(formData);
		let type = "POST";
		if(formData.rowKey !== null && formData.rowKey !== undefined) {
			type = "PUT";
		}

		user.apiCall("sensoroutage", formData, type)
			.then(() => {
				setOutageFormOpen(null);
				fetchData();
			})
			.catch(() => user.showErrorToast(outageSaveFail))
	}
	const handleOutageDelete = (data) => {
		user.apiCall(`sensoroutage/${data.rowKey}`, null, "DELETE")
			.then(() => {
				setDeleteOpen(null);
				fetchData();
			})
			.catch(() => user.showErrorToast(deleteFail))
	}
	const handleOutageTypeDelete = (data) => {
		user.apiCall(`outagetype/${data.rowKey}`, null, "DELETE")
			.then(() => {
				setDeleteTypeOpen(null);
				fetchData();
			})
			.catch(() => user.showErrorToast(deleteFail))
	}

	const prepOutageEdit = (rowData) => {
		let data = { ...rowData };
		data.issueIdentifiedDate = new Date(rowData.issueIdentifiedDate);
		data.startDate = new Date(rowData.startDate);
		if(rowData.endDate !== null && rowData.endDate !== undefined) {
			data.endDate = new Date(rowData.endDate);
		}

		setOutageFormOpen(data);
	}

	const outageHeader = () => (
		<>
			<Button onClick={() => setTypeTableOpen(true)}
				label="View Issue Types"
				icon="pi pi-list"
				className="issue-list p-button-raised p-button-text outage-primary"
			/>
			<Button onClick={() => setOutageFormOpen(true)}
				label="New Issue"
				icon="pi pi-plus"
				className="new-outage p-button-raised p-button-text outage-primary"
			/>
		</>
	)

	const actionBodyOutageTemplate = (rowData) => (
		<>
			<Button icon="pi pi-trash"
				label="Delete"
				onClick={() => setDeleteOpen(rowData)}
				className="p-button-raised p-button-text delete outage-primary"
			/>
			<Button icon="pi pi-pencil"
				label="Edit"
				onClick={() => prepOutageEdit(rowData)}
				className="p-button-raised p-button-text edit outage-primary"
			/>
		</>
	);

	const actionBodyTypeTemplate = (rowData) => (
		<>
			<Button icon="pi pi-trash"
				label="Delete"
				onClick={() => setDeleteTypeOpen(rowData)}
				className="p-button-raised p-button-text delete"
			/>
			<Button icon="pi pi-pencil"
				label="Edit"
				onClick={() => setTypeFormOpen(rowData)}
				className="p-button-raised p-button-text edit"
			/>
		</>
	);

	return (
		<div id="Outage">
			<h1>Device Issues</h1>
			<DataTable value={outageData}
				dataKey="rowKey"
				rowsPerPageOptions={[10, 25, 50]}
				header={outageHeader}
				sortField="issueIdentifiedDate"
				sortOrder={1}
				filterDisplay="row"
				paginator
				rows={25}
			>
				<Column field="deviceId"
					header="Device Id"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="deviceModel"
					header="Device Model"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="issueType"
					header="Issue Type"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="issueIdentifiedDate"
					header="Date Identified"
					body={(d) => format(Date.parse(d.issueIdentifiedDate), "M/d/yyyy")}
					showFilterMenu={false}
					sortable
				/>
				<Column field="affectedFlights"
					header="Flights Affected"
					showFilterMenu={false}
					sortable
				/>
				<Column field="deviceLocation"
					header="Location"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="startDate"
					header="Start Date"
					body={(d) => utcToLocalTemplate(d, "startDate")}
					showFilterMenu={false}
					sortable
				/>
				<Column field="endDate"
					header="End Date"
					body={(d) => utcToLocalTemplate(d, "endDate")}
					showFilterMenu={false}
					sortable
				/>
				<Column field="issueDescription"
					header="Description"
					showFilterMenu={false}
				/>
				<Column body={actionBodyOutageTemplate}
					className="action-col"
					showFilterMenu={false}
				/>
			</DataTable>
			<Dialog visible={typeTableOpen}
				header="Issue Types"
				onHide={() => setTypeTableOpen(false)}
				className="issue-type-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<DataTable value={issueTypeData}
					dataKey="rowKey"
					rows={10}
					rowsPerPageOptions={[10, 25]}
					header={
						<Button onClick={() => setTypeFormOpen({})}
							label="New Type"
							icon="pi pi-plus"
						/>
					}
					sortField="name"
					sortOrder="1"
					paginator
				>
					<Column field="name"
						showFilterMenu={false}
						filterMatchMode="contains"
						filter
					/>
					<Column body={actionBodyTypeTemplate}
						className="action-col"
						showFilterMenu={false}
					/>
				</DataTable>
			</Dialog>
			<Dialog visible={typeFormOpen !== null}
				header={`${typeFormOpen?.rowKey?.length > 0 ? "Edit" : "New"} Issue Type`}
				onHide={() => setTypeFormOpen(null)}
				className="issue-form-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<IssueTypeForm values={typeFormOpen} onSubmit={handleTypeSubmit} />
			</Dialog>
			<Dialog visible={outageFormOpen !== null}
				header={`${outageFormOpen?.rowKey?.length > 0 ? "Edit" : "New"} Device Issue`}
				onHide={() => setOutageFormOpen(null)}
				className="outage-form-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<SensorOutageForm placeList={placeList}
					outageTypesList={issueTypeData}
					values={outageFormOpen}
					onSubmit={handleOutageSubmit} />
			</Dialog>
			<Dialog visible={deleteOpen !== null}
				header="Delete Device Issue"
				onHide={() => setDeleteOpen(null)}
				className="outage-delete-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<p>Are you sure you wish to delete the following entry?</p>
				<p>Device: {deleteOpen?.deviceId}</p>
				<p>Location: {deleteOpen?.deviceLocation} {deleteOpen?.deviceType}</p>
				<p>Start: {utcToLocalTemplate(deleteOpen ?? {}, "startDate")}</p>
				<p>End: {utcToLocalTemplate(deleteOpen ?? {}, "endDate")}</p>
				<p>{deleteOpen?.issueDescription}</p>
				<Button label="Confirm"
					onClick={() => handleOutageDelete(deleteOpen)}
					className="delete-confirm"
				/>
			</Dialog>
			<Dialog visible={deleteTypeOpen !== null}
				header="Delete Issue Type"
				onHide={() => setDeleteTypeOpen(null)}
				className="outagetype-delete-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<p>Are you sure you wish to delete the following entry?</p>
				<p>{deleteTypeOpen?.name}</p>
				<Button label="Confirm"
					onClick={() => handleOutageTypeDelete(deleteTypeOpen)}
					className="delete-confirm"
				/>
			</Dialog>
		</div>
	);
}
