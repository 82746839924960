import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../UserContext.js';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { OrderList } from 'primereact/orderlist';
import { Checkbox } from 'primereact/checkbox';
import { getFormErrorMessage } from '../utilities/FormHelpers';
import { DataUrlUploader } from '../components/DataUrlUploader.js';
import { ToggleButton } from 'primereact/togglebutton';
const tagErrorMsg = "Could not load tag options";
const maxImgsMsg = "Unable to add image. Maximum number of images reached";

export const PortalCardForm = (props) => {
	const [tagOptions, setTagOptions] = useState([]);
	const [images, setImages] = useState([]);
	const [displayType, setDisplayType] = useState({});
	const [showOnDevToggle, setShowOnDevToggle] = useState(true);
	const [showOnProdToggle, setShowOnProdToggle] = useState(true);
	const user = useContext(UserContext);
	const multiRef = useRef(null);

	useEffect(() => {
		user.apiCall("portaltags")
			.then((res) => setTagOptions(res.data))
			.catch((ex) => user.showErrorToast(tagErrorMsg));

		let imageList = [];
		if(props.values.imgUrl1 && props.values.imgUrl1 !== "") {
			imageList.push({position: 1, link: props.values.imgUrl1});
		}
		if(props.values.imgUrl2 && props.values.imgUrl2 !== "") {
			imageList.push({position: 2, link: props.values.imgUrl2});
		}
		if(props.values.imgUrl3 && props.values.imgUrl3 !== "") {
			imageList.push({position: 3, link: props.values.imgUrl3});
		}
		if(props.values.imgUrl4 && props.values.imgUrl4 !== "") {
			imageList.push({position: 4, link: props.values.imgUrl4});
		}
		setImages(imageList);
	},[])

	/****** BUG WITH PRIMEREACT LIBRARY ******/
	/* Must set display type after load if items are already selected */
	useEffect(() => {
		if(tagOptions.length === 0 || !multiRef.current) {
			return;
		}
		if(multiRef.current.props.display !== "chip") {
			setDisplayType({display: "chip"});
		}
	},[tagOptions, multiRef.current])

	const handleImageChange = (imgData) => {
		imgData.forEach((val, idx) => val.position = idx + 1);
		setImages(imgData);
	}

	const validate = (formData) => {
		let errors = {};

		if(!formData.appTitle || formData.appTitle === "") {
			errors.appTitle = "Title required"
		}
		if(!formData.appDescShort || formData.appDescShort === "") {
			errors.appDescShort = "Short Description required"
		}
		if(!formData.appDescLong || formData.appDescLong === "") {
			errors.appDescLong = "Long Description required"
		}
		if(!formData.appUrl || formData.appUrl === "") {
			errors.appUrl = "Url required"
		}
		if(!formData.rawDataSource || formData.rawDataSource === "") {
			errors.rawDataSource = "Original Data Source required"
		}
		if(!formData.dataSource || formData.dataSource === "") {
			errors.dataSource = "Data Source required"
		}
		if(!formData.refreshSchedule || formData.refreshSchedule === "") {
			errors.refreshSchedule = "Refresh Schedule required"
		}

		if(!formData.tags || formData.tags.length === 0) {
			errors.tags = "At least one Tag required"
		}

		if(images.length === 0) {
			errors.images = "At least one image required"
		}

		return errors;
	}

	const handleImageAdd = (data) => {
		if(data.length > 4) {
			user.showErrorToast(maxImgsMsg);
			return;
		}
		data.forEach((val, idx) => val.position = idx + 1);
		setImages(data);
	}

	const handleImageDelete = (position) => {
		setImages((prev) => {
			prev.splice(position - 1, 1);
			prev.forEach((val, idx) => val.position = idx + 1);
			return [...prev];
		})
	}

	const handleSubmit = (data) => {
		console.log(data);	
		data.showOnDev = showOnDevToggle;
		data.showOnProd= showOnProdToggle;
		[...Array(4)].forEach((val, idx) => {
			if(images[idx] !== null && images[idx] !== undefined) {
				data[`imgUrl${idx + 1}`] = images[idx];
			}
			else {
				data[`imgUrl${idx + 1}`] = null;
			}
		});
		
		props.onSubmit(data);
	}

	const imageTemplate = (data) => (
		<>
			<img src={data.link ?? data.dataUrl} />
			<i className="pi pi-trash" onClick={() => handleImageDelete(data.position)} />
			{data.position === 1 && (<div>THUMBNAIL</div>)}
		</>
	)

	const displayInput = (data) =>{
		console.log(data.value);
	}
	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit}>
					<Field name="appTitle"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputText {...input} id="appTitle" />
									<label htmlFor='appTitle'>App Title</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="appDescShort"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputTextarea {...input}
										id="appDescShort"
										rows={5}
										cols={30}
									/>
									<label htmlFor='appDescShort'>Short Description</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="appDescLong"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputTextarea {...input}
										id="appDescLong"
										rows={5}
										cols={30}
									/>
									<label htmlFor='appDescLong'>Long Description</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="tags"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<MultiSelect {...input}
										ref={multiRef}
										id="tags"
										options={tagOptions}
										{...displayType}
									/>
									<label htmlFor='tags'>Tags</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="images"
						render={({ meta }) => (
							<div className="full">
								<div className="orderlist-upload">
									<p>Portal Card Images</p>
									<DataUrlUploader imageList={images} setImageList={handleImageAdd} />
								</div>
								<OrderList value={images}
									id="images"
									onChange={(e) => handleImageChange(e.value)}
									itemTemplate={imageTemplate}
									className={`length-${images.length}`}
								/>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="appUrl"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputText {...input} id="appUrl" />
									<label htmlFor='appUrl'>App Url</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="rawDataSource"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputText {...input} id="rawDataSource" />
									<label htmlFor='rawDataSource'>Original Data Source</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="refreshSchedule"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputText {...input} id="refreshSchedule" />
									<label htmlFor='refreshSchedule'>Refresh Schedule</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="dataSource"
						render={({ input, meta }) => (
							<div className="full">
								<span className="p-float-label">
									<InputText {...input} id="dataSource" />
									<label htmlFor='dataSource'>Data Source</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="showOnProd"
						render={({ input, meta }) => (
							<div className="toggle-button">
								<label htmlFor='showOnProd'>Show on Prod</label>
								<ToggleButton checked={showOnProdToggle} onChange={(e) => setShowOnProdToggle(e.value)} style={{marginLeft:'2rem'}} id="showOnProd"/>
								{displayInput(input)}
								{getFormErrorMessage(meta)}
							</div>
					)}/>
					<Field name="showOnDev"
						render={({ input, meta }) => (
							<div className="toggle-button">
								<label htmlFor='showOnDev'>Show on Dev</label>
								<ToggleButton checked={showOnDevToggle} onChange={(e) => setShowOnDevToggle(e.value)} style={{marginLeft:'2rem'}} id="showOnDev"/>
								{displayInput(input)}
								{getFormErrorMessage(meta)}
							</div>
					)}/>
					
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}