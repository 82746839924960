import React from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getFormErrorMessage } from '../utilities/FormHelpers';

/*
	values: {},
	onSubmit: () => {}
*/
export const IssueTypeForm = (props) => {
	const validate = (formData) => {
		let errors = {};

		if(!formData.name || formData.name === "") {
			errors.name = "Name required"
		}

		return errors;
	}

	const handleSubmit = (data) => {
		props.onSubmit(data);
	}

	return (
		<Form onSubmit={handleSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit}>
					<Field name="name"
						render={({ input, meta }) => (
							<div className="w12">
								<span className="p-float-label">
									<InputText {...input} id="name" />
									<label htmlFor='name'>Name</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}