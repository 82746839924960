import React, { createContext, useState, useEffect, useRef, useLayoutEffect } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from "primereact/toast";
import { useMsal } from "@azure/msal-react";
import  useAxiosInstance from './auth/TokenAxiosHandler';


const UserContext = createContext();
const baseUrl = process.env.REACT_APP_TEMPLATE_API;
const authUrl = process.env.REACT_APP_AUTH_API;
const baseKey = process.env.REACT_APP_TEMPLATE_KEY;
const authKey = process.env.REACT_APP_AUTH_KEY;

// It's in an array so VSCode can minimize it
const testPhoto = [
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA/CAYAAACxdtubAAAABGdBTUEAALGOfPtRkwAAACBjSFJNAACHDwAAjA8AAP1SAACBQAAAfXkAAOmLAAA85QAAGcxzPIV3AAAKL2lDQ1BJQ0MgUHJvZmlsZQAASM" +
	"edlndUVNcWh8+9d3qhzTDSGXqTLjCA9C4gHQRRGGYGGMoAwwxNbIioQEQREQFFkKCAAaOhSKyIYiEoqGAPSBBQYjCKqKhkRtZKfHl57+Xl98e939pn73P32XuftS4AJE8fLi8FlgIgmSfgB3o401eFR9Cx/QAGeIABpgAwWempvkHuwU" +
	"AkLzcXerrICfyL3gwBSPy+ZejpT6eD/0/SrFS+AADIX8TmbE46S8T5Ik7KFKSK7TMipsYkihlGiZkvSlDEcmKOW+Sln30W2VHM7GQeW8TinFPZyWwx94h4e4aQI2LER8QFGVxOpohvi1gzSZjMFfFbcWwyh5kOAIoktgs4rHgRm4iYxA" +
	"8OdBHxcgBwpLgvOOYLFnCyBOJDuaSkZvO5cfECui5Lj25qbc2ge3IykzgCgaE/k5XI5LPpLinJqUxeNgCLZ/4sGXFt6aIiW5paW1oamhmZflGo/7r4NyXu7SK9CvjcM4jW94ftr/xS6gBgzIpqs+sPW8x+ADq2AiB3/w+b5iEAJEV9a7" +
	"/xxXlo4nmJFwhSbYyNMzMzjbgclpG4oL/rfzr8DX3xPSPxdr+Xh+7KiWUKkwR0cd1YKUkpQj49PZXJ4tAN/zzE/zjwr/NYGsiJ5fA5PFFEqGjKuLw4Ubt5bK6Am8Kjc3n/qYn/MOxPWpxrkSj1nwA1yghI3aAC5Oc+gKIQARJ5UNz13/" +
	"vmgw8F4psXpjqxOPefBf37rnCJ+JHOjfsc5xIYTGcJ+RmLa+JrCdCAACQBFcgDFaABdIEhMANWwBY4AjewAviBYBAO1gIWiAfJgA8yQS7YDApAEdgF9oJKUAPqQSNoASdABzgNLoDL4Dq4Ce6AB2AEjIPnYAa8AfMQBGEhMkSB5CFVSA" +
	"sygMwgBmQPuUE+UCAUDkVDcRAPEkK50BaoCCqFKqFaqBH6FjoFXYCuQgPQPWgUmoJ+hd7DCEyCqbAyrA0bwwzYCfaGg+E1cBycBufA+fBOuAKug4/B7fAF+Dp8Bx6Bn8OzCECICA1RQwwRBuKC+CERSCzCRzYghUg5Uoe0IF1IL3ILGU" +
	"GmkXcoDIqCoqMMUbYoT1QIioVKQ21AFaMqUUdR7age1C3UKGoG9QlNRiuhDdA2aC/0KnQcOhNdgC5HN6Db0JfQd9Dj6DcYDIaG0cFYYTwx4ZgEzDpMMeYAphVzHjOAGcPMYrFYeawB1g7rh2ViBdgC7H7sMew57CB2HPsWR8Sp4sxw7r" +
	"gIHA+XhyvHNeHO4gZxE7h5vBReC2+D98Oz8dn4Enw9vgt/Az+OnydIE3QIdoRgQgJhM6GC0EK4RHhIeEUkEtWJ1sQAIpe4iVhBPE68QhwlviPJkPRJLqRIkpC0k3SEdJ50j/SKTCZrkx3JEWQBeSe5kXyR/Jj8VoIiYSThJcGW2ChRJd" +
	"EuMSjxQhIvqSXpJLlWMkeyXPKk5A3JaSm8lLaUixRTaoNUldQpqWGpWWmKtKm0n3SydLF0k/RV6UkZrIy2jJsMWyZf5rDMRZkxCkLRoLhQWJQtlHrKJco4FUPVoXpRE6hF1G+o/dQZWRnZZbKhslmyVbJnZEdoCE2b5kVLopXQTtCGaO" +
	"+XKC9xWsJZsmNJy5LBJXNyinKOchy5QrlWuTty7+Xp8m7yifK75TvkHymgFPQVAhQyFQ4qXFKYVqQq2iqyFAsVTyjeV4KV9JUCldYpHVbqU5pVVlH2UE5V3q98UXlahabiqJKgUqZyVmVKlaJqr8pVLVM9p/qMLkt3oifRK+g99Bk1JT" +
	"VPNaFarVq/2ry6jnqIep56q/ojDYIGQyNWo0yjW2NGU1XTVzNXs1nzvhZei6EVr7VPq1drTltHO0x7m3aH9qSOnI6XTo5Os85DXbKug26abp3ubT2MHkMvUe+A3k19WN9CP16/Sv+GAWxgacA1OGAwsBS91Hopb2nd0mFDkqGTYYZhs+" +
	"GoEc3IxyjPqMPohbGmcYTxbuNe408mFiZJJvUmD0xlTFeY5pl2mf5qpm/GMqsyu21ONnc332jeaf5ymcEyzrKDy+5aUCx8LbZZdFt8tLSy5Fu2WE5ZaVpFW1VbDTOoDH9GMeOKNdra2Xqj9WnrdzaWNgKbEza/2BraJto22U4u11nOWV" +
	"6/fMxO3Y5pV2s3Yk+3j7Y/ZD/ioObAdKhzeOKo4ch2bHCccNJzSnA65vTC2cSZ79zmPOdi47Le5bwr4urhWuja7ybjFuJW6fbYXd09zr3ZfcbDwmOdx3lPtKe3527PYS9lL5ZXo9fMCqsV61f0eJO8g7wrvZ/46Pvwfbp8Yd8Vvnt8H6" +
	"7UWslb2eEH/Lz89vg98tfxT/P/PgAT4B9QFfA00DQwN7A3iBIUFdQU9CbYObgk+EGIbogwpDtUMjQytDF0Lsw1rDRsZJXxqvWrrocrhHPDOyOwEaERDRGzq91W7109HmkRWRA5tEZnTdaaq2sV1iatPRMlGcWMOhmNjg6Lbor+wPRj1j" +
	"FnY7xiqmNmWC6sfaznbEd2GXuKY8cp5UzE2sWWxk7G2cXtiZuKd4gvj5/munAruS8TPBNqEuYS/RKPJC4khSW1JuOSo5NP8WR4ibyeFJWUrJSBVIPUgtSRNJu0vWkzfG9+QzqUvia9U0AV/Uz1CXWFW4WjGfYZVRlvM0MzT2ZJZ/Gy+r" +
	"L1s3dkT+S453y9DrWOta47Vy13c+7oeqf1tRugDTEbujdqbMzfOL7JY9PRzYTNiZt/yDPJK817vSVsS1e+cv6m/LGtHlubCyQK+AXD22y31WxHbedu799hvmP/jk+F7MJrRSZF5UUfilnF174y/ariq4WdsTv7SyxLDu7C7OLtGtrtsP" +
	"toqXRpTunYHt897WX0ssKy13uj9l4tX1Zes4+wT7hvpMKnonO/5v5d+z9UxlfeqXKuaq1Wqt5RPXeAfWDwoOPBlhrlmqKa94e4h+7WetS212nXlR/GHM44/LQ+tL73a8bXjQ0KDUUNH4/wjowcDTza02jV2Nik1FTSDDcLm6eORR67+Y" +
	"3rN50thi21rbTWouPguPD4s2+jvx064X2i+yTjZMt3Wt9Vt1HaCtuh9uz2mY74jpHO8M6BUytOdXfZdrV9b/T9kdNqp6vOyJ4pOUs4m3924VzOudnzqeenL8RdGOuO6n5wcdXF2z0BPf2XvC9duex++WKvU++5K3ZXTl+1uXrqGuNax3" +
	"XL6+19Fn1tP1j80NZv2d9+w+pG503rm10DywfODjoMXrjleuvyba/b1++svDMwFDJ0dzhyeOQu++7kvaR7L+9n3J9/sOkh+mHhI6lH5Y+VHtf9qPdj64jlyJlR19G+J0FPHoyxxp7/lP7Th/H8p+Sn5ROqE42TZpOnp9ynbj5b/Wz8ee" +
	"rz+emCn6V/rn6h++K7Xxx/6ZtZNTP+kv9y4dfiV/Kvjrxe9rp71n/28ZvkN/NzhW/l3x59x3jX+z7s/cR85gfsh4qPeh+7Pnl/eriQvLDwG/eE8/s3BCkeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAIXRFWHRDcmVhdGlvbiBUaW1lAD" +
	"IwMTY6MTI6MDIgMTE6NTA6NTBD+Oz6AAAMdUlEQVRoQ91bCXRU1Rn+ZpLMZLLvxASQTUCKqFjaIlULFSmlFJAqaNUet9ZabdGjx2L1aFXaKrW24FK3KtQIHgMKAi2RYgsqRoGgJoEYwCRsSchkn0xmS/p/970hkw3yXibU8uW8ZN598+" +
	"673/2X+///fbG0CzAAOFrdjCNyvL/7CMqONqHa2QKf3w+bLRK2SCva2wCX149BSdEYlp2EKRdlITs9DmdnJeg9hBdhJ+pu9eHJlbuR934Zjhx3ITnBDntUJCIjLeBPO3/4RAtglV/+QBs8Qri+yYu05GhMuTALd90wEdkZ8VqHYUJYia" +
	"7J+wL3L/sQCXE2xDqiEEXJ9bF3ixD3B9rR4vahrqkVv1hwPn51/UT9av8RNqJLXsjHS2sKMWJwogxaRt0P8O6yY42YO3Uklt5zid7SP4SF6O9fzEfOhhJkpsfqLeFBpdOF+d8dhd/ecbHeYh5W/a9pbBdns+y1PcjKiNNbwoestFi8tL" +
	"YIG7cd1FvMo18SrW/2YO6d62C1WGG19l+9egKH1+oNYM1Ts5CZZn4y+yXRV9cWoqHRO2AkCdp7q8ePZ1Z/qreYg2miAVkWXt9UgiRZPgYaSfF25G4uxfHaFr3FOEwT3bStDI0un6yP/TbzU8IqUmWgsWJdsd5iHKZHuTxnDzJSontdJ8" +
	"OyZulgX6mJdryZV6o1mIApoo0ujxytIs0IvaUz2tra0SaqzagnDKuXAm21XfrdXVyttxiDKaKbt5dBghilUqEgqXqJatgcFxuFRImQmtSkeFVbf6CCEBntlo/K9RZjMEX0wOEGIamf6OA4GMgvmDEaOY/PxDtPz8W65XPw6J1TMOGcVJ" +
	"SU1cHrD+jfNgd6d2e9Wz8zBuNERWokZI3ouJWcDwr5J+7+Nu69aZKsd7GiZprazpgyDC89cgXyXpiHSJkNZ4MbLa1+7UaDiLRacaymRdZV4/cbJtrc4kN1ndhniC7WNXkwZ9pIzJ46Ss7EPklSrmuZCo82jB2eiq2vXIUn770UgyWKOl" +
	"zZBK8vYEilI0SiNbVuNDZ79Za+wzDRJiFaK+oTEaGNkEQiZKavmTlWP9fsiVe134RFIy8Xp31jKFYtnYmXH50OjyeAL0UT/H5JTvsAahEzm+aW00FUnIuz3nNCdb2+NowamohJ4wdpLHtBMKNREyP3TrkwG/mrr8FzD05TaR0Tc6rkyS" +
	"RMibpa/BIpGbd1w0S5ZHhlQEGP6/H5MWF0qvpMVe0LNHXWjukXD8P6p+fg17d8HdH2SHx5qEEtT6Hg94IIKM3QTwzAMFF5Dtrkh2AY2CD2OXFchjrX3JIxUKWJa2ediy0vzscTkn+6PZJ8N7YqG2ac65G/PjkILfb1qc9GYFyiyp7E0c" +
	"i0chBvLJ2F6ZOHdZp1I+DAg/ZLnbjy8nOwI+ca3L7wfDVvFms7li+eivRUhyxPfHY7GsVPGIVhooxt6WRom8MHJ4o3TVHtZokSHfbbIeGbrxyP3D/9AG/9ZQ4uPDcDY89OgVcmllqdFGc8kTBMNENmdmhWHHyBgKrxBIOA4GD7i1AJZ6" +
	"TEIC3Jodr5vCBJZjNGYZhofKwd6ckx1CAV87pllsONDglrWsKCWUWVSy4AZ6XFIDHBptqNwDBRh3hGzihDwEOy6B861qTa+6O6vSHY55HjzSg56FSRUbJIOD7mNEiUIFFaUlJcNB54eodqs8ogwo1gny+8+ZmyXy5frBOzjGoUpkbHzI" +
	"Q+I8YRqZaXyT9ehZ2FlUrlwiVX9sWoafqtudi28yhSRZIMJc3YJ2FSDLQhLvhCOiYKbncA6/+jVerC45I07CyqQrXEtvGxmk3SGdltPefAp4IpospZtGuUSDY12YFVG0rUuZJqP+1V90WqJhUbHaWdEPLMdv25RmGKqENm1RJyJweWmG" +
	"jHosff08/NDYbQJsmCvB3lKCiuQqxoTBDs1hZlTglN3cUNo67GyPVt+66jKD7g1KSqtxsBSdIBeSV+XvJ8PoYPSVIaEwR7jbafRtVl9tFTAG+PisAjz+2AT0K1rhWIU0Ejqd300LP5cLn9KlsJhZK1SW0xRbQjz+wMqtnnpbV4cPkHct" +
	"b3AXXYtAV/e6sQa98t7dm7in1yi9EMTBFlqtYTDY43Kz0WG/59EK++XaRmnwI4mXPiNX6PKrv5/XL88dVdyB7U89YDe/F6tVjYKEwRZZWhN+/XJgMflBaHJS9+jIee+VBaNBIk1NPBayT63OpPcefvtkqIFyt39Ny3VTKZVo/x6gJhii" +
	"hjTz1o6QJNOix1pCfFIPfd/bjxgTyUltcrQj0dzDmp6stf36O2Hblusnghc9AN3MyqrHFrojUIw0RZwSutqFMOqSskwVCTsPHZuUhPiUaUfKekrBbzfrkOs25/G6v/UYIDh+pwuKoJW3ZU4KcPv4vzrlypPjuiI1WCsCNnARwScdHzdk" +
	"WULC0HjjSgpsH4HoxhojV1LSja70R0twilHRXHGjDv8pFIkwCCNd3brj5PVQps4o1ZPXxMloxpN6+RkPEN3LFkKwpLnSperhIpzr5sBLavuFoiLRt+MnucetGDqh2KqMgIlB9uRMVRLZEwAsP7o9zCz80rVbFnEOyissaFxbdMwnUyyF" +
	"BQgi+vKULOxn3Kk2oTJIm7iN9Z14orLh6KRddPxPhz0rQbtEUEmz/4Enc/sR2DUmNExTtstqG5Vb3Q8ef7puotfUOfiVYca0SV0435d63HmGGpnWa7tsGNe26chGu/P0bO6GS0doI2G8Qnn1fii/I6tc6OlGDgkouy9SsCuYmOTPs+/1" +
	"qxatM+PLlil0xQtPYdAS+XltVj7bLZyJRJ6OtO+ymJrlhXhLwPyuFsaFX7k8kJ8tCQwROs2h2VnPG9V34kXjNOTQKPIEl+VlXDLveREO8NnQyC56z8z/zZWllq4jtJlOBmk0t8RVqSHQkSkV160WDctmCCfrVn9Eq0YF8V5i/aiETJHO" +
	"LkYMGakUrXQQXBiiAnY+WSGbjgXK0qGKz/8B7tKd0f1WkyFCELCvZW44bFm8WhObqRDILfDwjhQEBIiwN01rdg9dJZmHxBlv6NzuiR6KN//Qgr39mLIaIWoXsspwKXiiZZWhbOGIP7xF5PQPJIkVs3KfOzdtpBZvnrBRJsFKv3lOjE+g" +
	"pqBr35tTNH4+E7puitHehG9CmxiedzP0e2kOxNeicDC8zNLq/aO/3hd4Zj8a3flFY+4uR9MZp66u+7xdl4lOeNNDDBQZAKvTXVeNF1nV/G6kR02yeH8fMl/1J21l9whll9YL2H5crJ52di3IhUWS+jFOWWVh/q5frOwips/fiQuoeOxQ" +
	"zBrjhW04xnfzMNl00aord0IXrFrbnKyB32kGS3n6BSeLwBZUet0redaZY8kcVoOigmAjESLNAWO+uWeXBvJkpSya3iHIPh5InpW5ZTgIrKZnlo+EgSHDxtjd6aEktJdCBF1mDGtFwjaYsdzio8cERHyArhFl+Tr7eEEH3+zc/UvmU4H9" +
	"gVoXkHHzNQjyKHzPQYtQ679U1nRXTZawWaJE04HyMY2N67woIEWRb/8PIn6sxKp5EvEYvZWsxXGXZbFHYXV4lTbIWVIdmevVVhdUBfFdhFeHsP1sr66oK1YO9xWCQvPL1qdXpAH5CUGI1N2w7Aun3XYZVVDKAP+p+Cpdl9kgRYuaQwzz" +
	"tTERERoV4usXLThmHbmQpy41an9VsTMlWodibaKNHs8uGCMemw8pU2pl/cvT7TwOiWL35cJRytaSkxWHb/VBSW1qgLZwrIhZxY3hlyVnxHUM/C1lV3bYLb61demNtz/2+8gwkEsyKHJA+vPDYD40Zq70B1yl74Os3qf5YgZ8M+FO93Yp" +
	"AE3klxNpV881u9RYjsYKBs/GR9c+hMCFjJ4NvglcddGD8qFQtnjcXC741WL2gF0S3xJoK54sr1xXhry360SNoTo+9iMbViUk27ZnnlRKlDeunWUT+heta7Z6gaLJ3wXacA/7lNLnrEBjmeOVNH4KZ5X0Nqcox6z6IreiTaFSS+becR7C" +
	"qqRnW9C8edbrU2VTpb0MT36oWwTVSd6RgL25wEToD6KxNDTegom3RAxk2xqOofP5OMIhQQIvKXpRkePn874mMiJa2LlRTPjoyUWPXq+cRxmbh0YhYSWbA7BfpEtCtYUW9q9qjXV5nksjp/qIr/UegW4l61JcF/qnPJBPEaX77y+fzi2U" +
	"lOJCGMIyxWtc/KybHbrGpnm/F2QrxNvQQZ77AJqWgMzowTkjblM+g7eM14zgz8F84hvsX2f8V7AAAAAElFTkSuQmCC"
];
const DefaultUSER = {
	email: "testEmail@flypitt.com",
	photo: testPhoto[0],
	personalInfo: {
		firstName: "Test",
		lastName: "User",
		title: "Not Real"
	}
}

const UserContextProvider = ({ children }) => {
	const [userInfo, setUserInfo] = useState(null);
	const [userToken, setUserToken] = useState(null);
	const [loadingError, setLoadingError] = useState(null);
    const [loading, setLoading] = useState(true);
	const { instance, accounts } = useMsal();
	const activeAccount = instance.getActiveAccount();
	const axiosInstance = useAxiosInstance();


	const toast = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			const accountEmail = activeAccount?.username ? activeAccount?.username : null;

			let authHeader = {
				headers: {
					"ocp-apim-subscription-key": authKey,
				}
			};

			const fetchUserInfo = async (userEmail) => {
				const userInfoResponse = await axiosInstance.get(authUrl + `UserInfo?user=${userEmail}`, { authHeader });
				//set the email, because the user info response doesnt have it (maybe we can change this in auth)
				userInfoResponse.data.email = accountEmail;
				setUserInfo(userInfoResponse.data);
			}

				await fetchUserInfo(accountEmail); 

				setLoading(false);
			
		}
		fetchData();
	}, []);

	const apiCall = async (uri, body, operation) => {
		let retVal
		switch (operation?.toUpperCase()) {
			case 'PUT':
				await axiosInstance.put(baseUrl + uri, body, {
					headers: {
						"ocp-apim-subscription-key": baseKey,
					}
				})
					.then((res) => {
						retVal = res;
					});
				break;
			case 'POST':
				await axiosInstance.post(baseUrl + uri, body, {
					headers: {
						"ocp-apim-subscription-key": baseKey,
					}
				})
					.then((res) => {
						retVal = res;
					});
				break;
			case 'DELETE':
				await axiosInstance.delete(baseUrl + uri, {
					headers: {
						"ocp-apim-subscription-key": baseKey,
					}
				})
					.then((res) => {
						retVal = res;
					});
				break;
			case 'GET':
			default:
				await axiosInstance.get(baseUrl + uri, {
					headers: {
						"ocp-apim-subscription-key": baseKey,
					}
				})
					.then((res) => {
						retVal = res;
					});
				break;
		}

		return retVal;
	}

	const showSuccessToast = (msg, sticky = false) => {
		let params = {};
		if (!sticky) {
			params.life = 4000;
		}
		else {
			params.sticky = true;
		}

		toast.current.show({
			severity: "success",
			summary: "Success",
			detail: msg,
			...params
		})
	}

	const showErrorToast = (msg, sticky = false) => {
		let params = {};
		if (!sticky) {
			params.life = 4000;
		}
		else {
			params.sticky = true;
		}

		toast.current.show({
			severity: "error",
			summary: "Error",
			detail: msg,
			...params
		})
	}

	if (loadingError) {
		return (
			<UserContext.Provider value={{ userInfo }}>
				{loadingError}
			</UserContext.Provider>
		)
	}
	else if (loading === true) {
		return (
			<UserContext.Provider value={{ userInfo }}>
				<div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
			</UserContext.Provider>
		)
	}
	else {
		return (
			// the Provider gives access to the context to its children
			<UserContext.Provider value={{ userInfo, apiCall, showSuccessToast, showErrorToast }}>
				<Toast ref={toast} />
				{children}
			</UserContext.Provider>
		);
	}
};

export { UserContext, UserContextProvider };
