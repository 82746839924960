import React, { useEffect, useState,useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { getFormErrorMessage } from '../utilities/FormHelpers';
import { UserContext } from '../UserContext.js';

/*
    props:
    values {}
    onSubmit (data)
*/
export const ModelForm = (props) => {
	const [manufacturerOptions, setManufacturerOptions] = useState([]);
	const [modelTypeOptions, setModelTypeOptions] = useState([]);
	const user = useContext(UserContext)
	useEffect(() => {
		user.apiCall(`modelformoptions`)
			.then((res) => {
				setModelTypeOptions(res.data.modelTypes);
				setManufacturerOptions(res.data.manufacturers);
			})
	}, [])

	const validate = (formData) => {
		let errors = {};

		if(!formData.modelName || formData.modelName === "") {
			errors.modelName = "Name required";
		}

		if(!formData.manufacturerGuid) {
			errors.manufacturerGuid = "Manufacturer required";
		}

		if(!formData.modelTypeGuid) {
			errors.modelTypeGuid = "Model type required";
		}

		return errors;
	}

	const handleSubmit = (data) => {
		if(data.hasBattery === null || data.hasBattery === undefined) {
			data.hasBattery = false;
		}
		props.onSubmit(data);
	}

	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="modelName"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="modelName"
									/>
									<label htmlFor='modelName'>Name:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="manufacturerGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="manufacturerGuid"
										options={manufacturerOptions}
										optionLabel="label"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='manufacturerGuid'>Manufacturer:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="modelTypeGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="modelTypeGuid"
										options={modelTypeOptions}
										optionLabel="label"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='modelTypeGuid'>Model Type:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="hasBattery"
						type="checkbox"
						render={({ input, meta }) => (
							<div>
								<Checkbox {...input}
									id="hasBattery"
									value="hasBattery"
								/>
								<label htmlFor='hasBattery'>&nbsp;Is Battery Powered?</label>
								<Button type="button"
									className="acaa tooltip"
									icon="pi pi-question-circle"
									tooltip="Flag to show model type on battery table"
									tooltipOptions={{ showOnDisabled: true }}
									disabled
								/>
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}