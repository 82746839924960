import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext.js';
import { format } from "date-fns";
import { Button } from "primereact/button";

const dtFmt = "M/d/yy h:mm a";
const dataLoadErrMsg = "Failed to load heartbeat diagnostic data";
const manualMsg = "A heartbeat packet has been injected into the decoder. Please refresh in a few minutes to view new packet timestamps";
const manualFail = "Failed to inject heartbeat packet";

export const HeartbeatDiag = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [lastFetch, setLastFetch] = useState("")
	const [router, setRouter] = useState({});
	const [decoder, setDecoder] = useState({});
	const [pipeline, setPipeline] = useState({});

	const user = useContext(UserContext);

	useEffect(() => {
		fetchData();

		let timer = setInterval(() => {
			fetchData();
		}, 5 * 60 * 1000);

		return () => {
			clearInterval(timer);
		}
	}, []);

	const fetchData = () => {
		setIsLoading(true);
		user.apiCall("heartbeat")
			.then((res) => {
				setLastFetch(format(Date.now(), "M/d h:mm:ss a"));
				const one = res.data.find((val) => val.rowKey === "Router");
				const two = res.data.find((val) => val.rowKey === "Decoder");
				const three = res.data.find((val) => val.rowKey === "Pipeline");
				setRouter(one);
				setDecoder(two);
				setPipeline(three);
				setIsLoading(false);
			})
			.catch(() => {
				user.showErrorToast(dataLoadErrMsg, true);
				setIsLoading(false);
			});
	}

	const runManual = () => {
		user.apiCall("manual", null, "POST")
			.then(() => user.showSuccessToast(manualMsg))
			.catch(() => user.showErrorToast(manualFail, true));
	}

	const isRecent = (stamp) => {
		if(stamp === null || stamp === undefined) {
			return;
		}

		const diffMin = (Date.now() - Date.parse(stamp)) / (1000 * 60);
		if(diffMin < 31) {
			return "valid";
		}
	}

	const timeDiff = (start, end) => {
		const diffMilli = Math.abs(Date.parse(end) - Date.parse(start));
		const sec = Math.floor(diffMilli / 1000);
		const min = Math.floor(sec / 60);
		return `${fmtTwoDigi(min)} : ${fmtTwoDigi(sec % 60)}.${fmtTwoDigi(diffMilli % 1000)}`;
	}

	const fmtTwoDigi = (num) => {
		return `${num.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`;
	}

	const printRecent = (packetList, prop) => {
		let toReturn = null;
		packetList.forEach((val) => {
			if(val[prop] !== null && val[prop] !== undefined) {
				const dt = Date.parse(val[prop]);
				if(toReturn === null || dt > toReturn) {
					toReturn = dt;
				}
			}
		});

		if(toReturn !== null) {
			return format(toReturn, dtFmt);
		}
	}

	return (
		<div id="Heartbeat">
			<h1>
				Heartbeat Status
				{isLoading && <i className="pi pi-spin pi-spinner"/>}
			</h1>
			<Button onClick={runManual}
				label="Manual Inject"
				icon="pi pi-database"
				className="injector"
			/>
			<div className={`pipe box ${isRecent(decoder.decoderTimestamp)}`}>
				<h2>Decoder</h2>
				<div className="timestamp">
					Last Packet Injected
					<p>{decoder.eventDateTime && format(Date.parse(decoder.eventDateTime), dtFmt)}</p>
					Latest Packet Received
					<p>{decoder.decoderTimestamp && format(Date.parse(decoder.decoderTimestamp), dtFmt)}</p>
				</div>
			</div>
			<div className="pipe arrow">
				<div><i className="pi pi-arrow-right" /></div>
			</div>
			<div className={`pipe box ${isRecent(router.routerTimestamp)}`}>
				<h2>Router</h2>
				<div className="timestamp">
					Last Packet Injected
					<p>{router.eventDateTime && format(Date.parse(router.eventDateTime), dtFmt)}</p>
                    Latest Packet Received
					<p>{printRecent([router, decoder], "routerTimestamp")}</p>
				</div>
				<div className="processing">
					<p>Processing Time</p>
					<div>{timeDiff(decoder.routerTimestamp, decoder.decoderTimestamp)}</div>
				</div>
			</div>
			<div className="pipe arrow">
				<div><i className="pi pi-arrow-right" /></div>
			</div>
			<div className={`pipe box ${isRecent(pipeline.pipelineTimestamp)}`}>
				<h2>Pipeline</h2>
				<div className="timestamp">
					Last Packet Injected
					<p>{pipeline.eventDateTime && format(Date.parse(pipeline.eventDateTime), dtFmt)}</p>
                    Latest Packet Received
					<p>{printRecent([router, decoder, pipeline], "pipelineTimestamp")}</p>
				</div>
				<div className="processing">
					<p>Processing Times</p>
					<div>{timeDiff(decoder.pipelineTimestamp, decoder.routerTimestamp)}</div>
					<div>{timeDiff(router.pipelineTimestamp, router.routerTimestamp)}</div>
				</div>
			</div>
			<Button onClick={fetchData}
				label="Refresh"
				icon="pi pi-refresh"
				className="refresh"
			/>
			<div>Last data refresh:</div>
			<div>{lastFetch}</div>
		</div>
	)
}