import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext.js';
import { Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { CalendarWrap, DropdownWrap, FormWrapper, TextAreaWrap } from '@pitt-iot/acaa-form';
import { getFormErrorMessage } from '../utilities/FormHelpers.js';

const optsErrorMsg = "Issue type options failed to load";

/*
	values: {},
	onSubmit: () => {}
*/
export const SensorOutageForm = (props) => {
	const [issueTypesOpts, setIssueTypesOpts] = useState([]);
	const [deviceList, setDeviceList] = useState([]);

	const user = useContext(UserContext);

	useEffect(() => {
		// user.apiCall("outageform")
		// 	.then((res) => {
		// 		setIssueTypesOpts(res.data.typeOpts);
		// 		setDeviceList(res.data.deviceOpts);
		// 	})
		// 	.catch(() => user.showErrorToast(optsErrorMsg))
		console.log(props.placeList)
		setIssueTypesOpts(props.outageTypesList);
		//setPlaceList(props.placeList);

	},[])

	const validate = (formData) => {
		let errors = {};

		if(formData.endDate !== null && formData.endDate !== undefined && formData.endDate !== "") {
			const startDt = Date.parse(formData.startDate);
			const endDt = Date.parse(formData.endDate);

			if(endDt <= startDt) {
				errors.endDate = "End date must be after start date";
			}
		}

		return errors;
	}

	return (
		<FormWrapper onSubmit={(data) => props.onSubmit(data)}
			initialVals={props.values}
			validator={validate}
		>
			<Field name="deviceLocation"
				render={({ input, meta }) => (
					<div className="w4">
						<span className="p-float-label">
							<Dropdown {...input}
								id="deviceLocation"
								options={props.placeList}
								optionLabel="placeName"
								optionValue="placeName"
								filter
							/>
							<label htmlFor='deviceLocation'>Place</label>
						</span>
						{getFormErrorMessage(meta)}
					</div>
				)}/>
				<Field name="issueType"
				render={({ input, meta }) => (
					<div className="w4">
						<span className="p-float-label">
							<Dropdown {...input}
								id="issueType"
								options={issueTypesOpts}
								optionLabel="name"
								optionValue="name"
								filter
							/>
							<label htmlFor='issueType'>Issue Type</label>
						</span>
						{getFormErrorMessage(meta)}
					</div>
				)}/>
			{/* <DropdownWrap name="placeName"
				label="Place Name"
				opts={props.placeList}
				size="4"
				isRequired
			/> */}
			{/* <DropdownWrap name="issueType"
				label="Issue Type"
				opts={issueTypesOpts}
				size="4"
				isRequired
			/> */}
			<CalendarWrap name="issueIdentifiedDate"
				label="Issue Identified"
				size="4"
				isRequired
			/>
			<CalendarWrap name="startDate"
				label="Start Date"
				size="4"
				hours="12"
				isRequired
			/>
			<CalendarWrap name="endDate"
				label="End Date"
				size="4"
				hours="12"
			/>
			<TextAreaWrap name="issueDescription"
				label="Description"
				row="3"
				col="20"
			/>
		</FormWrapper>
	);
}