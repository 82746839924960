export const validateLatCoord = (value) => {
    let parsed = parseFloat(value);
    if (!isNaN(parsed) && parsed >= -90 && parsed <= 90) {
        return undefined;
    }
    return "Latitude must be between -90 and 90.  ";
}

export const validateLngCoord = (value) => {
    let parsed = parseFloat(value);
    if (!isNaN(parsed) && parsed >= -180 && parsed <= 180) {
        return undefined;
    }
    return "Longitude must be between -180 and 180.  ";
}