import React, { useEffect, useState, useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../utilities/FormHelpers'
import { UserContext } from '../UserContext.js';

/*
    props:
    values {}
    onSubmit (data)
*/
export const PlaceTypeForm = (props) => {
	const [placeTypeCatOptions, setPlaceTypeCatOptions] = useState([]);
	const user = useContext(UserContext)
	useEffect(() => {
		user.apiCall(`placetypeformoptions`)
			.then((res) => setPlaceTypeCatOptions(res.data))
	}, [])

	const validate = (formData) => {
		let errors = {};

		if(!formData.placeTypeName || formData.placeTypeName === "") {
			errors.placeTypeName = "Name required";
		}

		if(!formData.placeTypeCatGuid) {
			errors.placeTypeCatGuid = "Place type cat required";
		}

		return errors;
	}

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="placeTypeName"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="placeTypeName"
									/>
									<label htmlFor='placeTypeName'>Name:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="placeTypeCatGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="placeTypeCatGuid"
										options={placeTypeCatOptions}
										optionLabel="label"
										style={{minWidth: '12rem'}}
									/>
									<label htmlFor='placeTypeCatGuid'>Place Type Category:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}