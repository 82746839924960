import React, { useState, useEffect, useContext } from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import { UserContext } from "../UserContext.js";

export const BatteryChart = (props) => {
	const [chartData, setChartData] = useState({});
	const [loading, setLoading] = useState(true);

	const user = useContext(UserContext);

	useEffect(() => {
		if(props.guid) {
			user.apiCall("batteries/history/" + props.guid)
				.then((res) => {
					// TODO: Error checking
					setChartData({
						labels: res.data.labels,
						datasets: [{
							label: props.guid,
							data: res.data.data,
							borderColor: "#ff6384"
						}]
					});
					setLoading(false);
				})
				.catch((ex) => user.showErrorToast("History data failed to load."));
		}
	}, []);

	return (
		<>
			{loading && (
				<ProgressSpinner className="battery-spinner" />
			)}
			{!loading && (
				<div>
					<Chart key={props.guid}
						data={chartData}
						type="line"
						height="70"
					/>
				</div>
			)}
		</>
	);
};
