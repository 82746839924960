import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from '../UserContext.js';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { DeviceForm } from "../forms/DeviceForm";
import { booleanTemplate, utcToLocalTemplate } from "../utilities/ColumnUtil";

export const DeviceDetails = () => {
	const [selectedEntry, setSelectedEntry] = useState({});
	const [showForm, setShowForm] = useState(false);
	const [showSubmitD, setShowSubmitD] = useState(false);
	const [deviceHistory, setDeviceHistory] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [sourceOptions, setSourceOptions] = useState([]);
	const [modelName, setModelName] = useState("");
	const [source, setSource] = useState("");

	const user = useContext(UserContext);
	const params = useParams();
	const deviceId = params.id; // props.location.pathname.split("/").pop();

	useEffect(() => {
		user.apiCall(`devicehistory/${deviceId}`)
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast('Device history data failed to load');
					return;
				}

				res.data.sensorHistory.sort((a, b) => {
					return (a.collectionEndDate > b.collectionEndDate ? -1 : 1);
				})

				setModelName(res.data.sensorHistory[0].modelGuid);
				setSource(res.data.sensorHistory[0].sourceGuid);
				setDeviceHistory(res.data.sensorHistory);

				setModelOptions(res.data.models);
				setSourceOptions(res.data.sources);
			})
			.catch((error) => {
				console.log(error)
				user.showErrorToast('Device history data failed to load');
			})
	},[])

	useEffect(() => {
		if(deviceHistory.length === 0) {
			return;
		}
		if(modelName !== deviceHistory[0].modelGuid || source !== deviceHistory[0].sourceGuid) {
			setShowSubmitD(true);
		}
		else {
			setShowSubmitD(false);
		}
	},[modelName, source, deviceHistory])

	const openModal = (entryData) => {
		setSelectedEntry(entryData);
		setShowForm(true);
	}

	const openModalEdit = (entryData) => {
		let copiedData = {...entryData};
		copiedData.collectionEndDate = new Date(entryData.collectionEndDate);
		copiedData.collectionStartDate = new Date(entryData.collectionStartDate);
		copiedData.place = {
			value: entryData.placeGuid,
			label: entryData.placeName
		};

		setSelectedEntry(copiedData);
		setShowForm(true);
	}

	const handleSensorCSubmit = (data) => {
		const body = {
			sensorGuid: deviceHistory[0].sensorGuid,
			placeGuid: data.placeGuid,
			sensorCatGuid: data.sensorCatGuid,
			isCurrentSensor: data.isCurrentSensor,
			collectionEndDate: data.collectionEndDate,
			collectionStartDate: data.collectionStartDate,
			assetGuid: data.assetGuid
		};

		if(!data.curSensorGuid) {
			user.apiCall(`iotsensorc`, body, 'POST')
				.then((res) => {
					setDeviceHistory(res.data);
				})
				.catch((error) => {
					user.showErrorToast(error.response.data);
				})
				.then(() => {
					setShowForm(false);
				});
		}
		else {
			body.curSensorGuid = data.curSensorGuid
			user.apiCall(`iotsensorc`, body,'PUT')
				.then((res) => {
					let newHistory = deviceHistory;
					const idx = newHistory.findIndex((val) => val.curSensorGuid === res.data.curSensorGuid);
					newHistory[idx] = res.data;

					setDeviceHistory(newHistory);
				})
				.catch((error) => {
					user.showErrorToast(error.response.data);
				})
				.then(() => {
					setShowForm(false);
				});
		}
	}

	const handleSensorDSubmit = () => {
		const body = {
			sensorGuid: deviceHistory[0].sensorGuid,
			modelGuid: modelName,
			sourceGuid: source,
			sensorUid: deviceHistory[0].sensorUid
		};

		user.apiCall(`iotsensord`, body, "PUT")
			.then((res) => {
				let newHistory = deviceHistory;
				newHistory[0].modelGuid = res.data.modelGuid;
				newHistory[0].sourceGuid = res.data.sourceGuid;

				setDeviceHistory(newHistory);
			})
			.catch(() => {
				user.showErrorToast("Core sensor data failed to update");
			})
			.then(() => {
				setShowSubmitD(false);
			});
	}

	const tableHeader = () => (
		<h2>Sensor Assignments</h2>
	);

	const actionHeader = () => (
		<Button icon="pi pi-plus"
			className="p-button-raised p-button-text"
			onClick={() => openModal({})}
		>
            New
		</Button>
	);

	const actionTemplate = (rowData) => (
		<Button icon="pi pi-pencil"
			className="p-button-raised p-button-text"
			onClick={() => openModalEdit(rowData)}
		>
            Edit
		</Button>
	);

	const leftToolbar = () => (
		<div style={{paddingTop: '8px'}}>
			<span className="p-float-label" style={{display: 'inline-block'}}>
				<Dropdown value={modelName}
					options={modelOptions}
					onChange={(e) => setModelName(e.value)}
					placeholder="Sensor Model"
					inputId="model"
					style={{marginRight: '8px', minWidth: '10rem'}}
				/>
				<label htmlFor="model">Sensor Model</label>
			</span>
			<span className="p-float-label" style={{display: 'inline-block'}}>
				<Dropdown value={source}
					options={sourceOptions}
					onChange={(e) => setSource(e.value)}
					placeholder="Source"
					inputId="source"
					style={{minWidth: '10rem'}}
				/>
				<label htmlFor="source">Source</label>
			</span>
			{deviceHistory.length > 0 && showSubmitD && (
				<Button onClick={() => handleSensorDSubmit()} style={{marginLeft: "4px"}}>
                        Submit
				</Button>
			)}
		</div>
	);

	return (
		<div id="DeviceDetails">
			<h1>DeviceID: {deviceId}</h1>
			<div className="card">
				<Toolbar left={leftToolbar} style={{marginBottom: '12px'}} />
				<DataTable value={deviceHistory.length > 0 && deviceHistory[0].curSensorGuid !== null ? deviceHistory : []}
					
					header={tableHeader}
				>
					<Column field="placeName" header="Place" />
					<Column field="sensorCatName" header="Sensor Category" />
					<Column field="assetName" header="Asset" />
					<Column field="collectionStartDate"
						header="Start Date"
						body={(d) => utcToLocalTemplate(d, "collectionStartDate")}
					/>
					<Column field="collectionEndDate"
						header="End Date"
						body={(d) => utcToLocalTemplate(d, "collectionEndDate")}
					/>
					<Column field="isCurrentSensor"
						header="Is Current"
						body={(data) => booleanTemplate(data, "isCurrentSensor")}
					/>
					<Column field=""
						body={actionTemplate}
						header={actionHeader}
						className="action-column"
					/>
				</DataTable>
			</div>
			<Dialog visible={showForm}
				header="Device Form"
				onHide={() => setShowForm(false)}
				style={{width: '25vw', marginRight:'100px'}}
				draggable={false}
				resizable={false}
				dismissableMask
			>
				<DeviceForm values={selectedEntry}
					onSubmit={handleSensorCSubmit}
				/>
			</Dialog>
		</div>
	);
};
