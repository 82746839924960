import React, { useState, useEffect,useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { CascadeSelect } from 'primereact/cascadeselect'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Calendar } from 'primereact/calendar'
import { ProgressSpinner } from "primereact/progressspinner"
import { InputText } from 'primereact/inputtext'
import { getFormErrorMessage } from '../utilities/FormHelpers'
import { UserContext } from '../UserContext.js';

const ButtonStyle = {
	backgroundColor: "#CCCCCC",
	color: "#000000",
	display: "block",
	marginTop: "8px"
}

const spinnerStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "40px",
	height: "40px",
	marginTop: "8px"
}

const assetWarningStyle = {
	width: "100%",
	backgroundColor: "#EEEEEE",
	borderRadius: "4px",
	padding: "3px"
}

/*
    values {}
    onSubmit (data)
*/
export const DeviceForm = (props) => {
	const [locations, setLocations] = useState([]);
	const [sensorCategories, setSensorCategories] = useState([]);
	const [assetOptions, setAssetOptions] = useState([]);
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [clearWarningOpen, setClearWarningOpen] = useState(false);
	const [clearCheckText, setClearCheckText] = useState("");
	const [disableEndSelector, setDisableEndSelector] = useState(props.values.isCurrentSensor);

	const user = useContext(UserContext);

	useEffect(() => {
		user.apiCall(`deviceformoptions`)
			.then((res) => {
				if(res.status !== 200) {
					//displayToastError();
					return;
				}

				if(props.values.assetGuid !== null &&
                props.values.assetGuid !== undefined &&
                props.values.assetGuid !== "") {
					// Add the currently selected sensor so it shows up in the list/input field
					const exists = res.data.assets.find((val) => val.assetGuid === props.values.assetGuid)
					if(exists === null || exists === undefined) {
						res.data.assets = [
							{ label: props.values.assetName, value: props.values.assetGuid },
							...res.data.assets
						];
					}
				}
				setAssetOptions(res.data.assets);
				setLocations(res.data.locations);
				setSensorCategories(res.data.sensorCategories);

				const dateEnd = new Date(props.values.collectionEndDate);
				if(dateEnd.getDate() === new Date(3000, 12, 31).getDate()) {
					delete props.values.collectionEndDate;
				}
			});
	},[])

	const validate = (formData) => {
		let errors = {};

		if(!formData.place) {
			errors.place = "Place required";
		}
		if(!formData.sensorCatGuid) {
			errors.sensorCatGuid = "Sensor category required";
		}
		if(!formData.collectionStartDate) {
			errors.collectionStartDate = "Start date required";
		}
		if(!formData.collectionEndDate && !formData.isCurrentSensor) {
			errors.collectionEndDate = "End date or set as current required";
		}

		return errors;
	}

	const handleSubmit = (data) => {
		setHasSubmitted(true);
		if(data.isCurrentSensor) {
			data.collectionEndDate = new Date(3000, 12, 31);
		}

		// When sending in empty/new object, the Field-handled checkbox input does not add a start value
		if(data.isCurrentSensor === null || data.isCurrentSensor === undefined) {
			data.isCurrentSensor = false;
		}
		data.placeGuid = data.place.value;
		props.onSubmit(data);
	}

	const closeClearCheck = () => {
		setClearWarningOpen(false);
		setClearCheckText("");
	}

	return (
		<div id="DeviceForm">
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={props.values}
			mutators={{
				Date: (args, state, utils) => {
					utils.changeValue(state, args[0], () => new Date());
				},
				toggleDisabled: (args, state, utils) => {
					setDisableEndSelector(args[0]);
				},
				clearAsset: (args, state, utils) => {
					utils.changeValue(state, "assetGuid", () => null)
				}
			}}
			render={({form, handleSubmit, values}) => {
				//https://www.youtube.com/watch?v=v1JAUiqskiw&t=818s - render efficiency
				return (
					<form onSubmit={handleSubmit} className="acaa-form">
						<Field name="place"
							render={({ input, meta }) => (
								<div>
									<span className="p-float-label">
										<CascadeSelect {...input}
											id="place"
											value={input.value}
											options={locations}
											optionGroupChildren={['buildings', 'levels', 'sections', 'places']}
											optionGroupLabel="name"
											optionLabel="label"
											onChange={(e) => input.onChange(e.value)}
											style={{width:"50%", minWidth:"20%" }}
										/>
										<label htmlFor='place'>Place:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="sensorCatGuid"
							render={({ input, meta }) => (
								<div>
									<span className="p-float-label">
										<Dropdown {...input}
											id="sensorCatGuid"
											options={sensorCategories}
											optionLabel="label"
										/>
										<label htmlFor='sensorCatGuid'>Sensor Category:&nbsp;</label>
									</span>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="collectionStartDate"
							render={({ input, meta }) => (
								<div>
									<span className="p-float-label">
										<Calendar {...input}
											id="collectionStartDate"
											showIcon />
										<label htmlFor='collectionStartDate'>Start Date:&nbsp;</label>
									</span>
									<Button type="button"
										style={ButtonStyle}
										onClick={() => form.mutators.Date("collectionStartDate")}
									>
                                    Set to Today
									</Button>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="collectionEndDate"
							render={({ input, meta }) => (
								<div>
									<span className="p-float-label">
										{!disableEndSelector && (
											<Calendar {...input}
												id="collectionEndDate"
												showIcon
											/>
										)}
										{disableEndSelector && (
											<Calendar
												disabled
												showIcon
											/>
										)}
										<label htmlFor='collectionEndDate'>End Date:&nbsp;</label>
									</span>
									<Button type="button"
										style={ButtonStyle}
										onClick={() => form.mutators.Date("collectionEndDate")}
										disabled={disableEndSelector}
									>
                                    Set to Today
									</Button>
									{getFormErrorMessage(meta)}
								</div>
							)}/>
						<Field name="isCurrentSensor"
							type="checkbox"
							render={({ input }) => (
								<div>
									<Checkbox {...input}
										id="isCurrentSensor"
										onChange={(e) => {
											input.onChange(e);
											form.mutators.toggleDisabled(e.checked);
											if(!e.checked) {
												form.mutators.Date("collectionEndDate");
											}
										}}
									/>
									<label className="checkbox" htmlFor='isCurrentSensor'>&nbsp;Is Current Location?</label>
									<Button type="button"
										className="acaa tooltip"
										icon="pi pi-question-circle"
										tooltip="Overrides end date"
										tooltipOptions={{ showOnDisabled: true }}
										disabled
									/>
								</div>
							)}/>
						<Field name="assetGuid"
							render={({ input }) => (
								<div className='full with-button'>
									<span className="p-float-label">
										<Dropdown {...input}
											id="assetGuid"
											options={assetOptions}
											optionLabel="label"
										/>
										<label htmlFor='assetGuid'>Asset (optional):&nbsp;</label>
									</span>
									<Button label="Clear"
										type="button"
										disabled={clearWarningOpen}
										onClick={() => setClearWarningOpen(true)}
									/>
								</div>
							)}/>
						{clearWarningOpen && (
							<div style={assetWarningStyle}>
								<p>
                                    If this sensor has been <b>moved</b>, either to storage or to another asset,
									{" "}clearing the value on this entry is not what you are looking to do.
								</p>
								<p>
                                    Instead, please set an end date and submit the entry with the change.
								</p>
								<hr />
								<p>
                                    If this sensor was, in fact, linked to the listed asset by <b>mistake</b>, please type
									{` "CLEAR"`} in all capital letters in the box below to unlock the clear button.
								</p>
								<InputText value={clearCheckText}
									onChange={(e) => setClearCheckText(e.target.value)}
								/>
								<Button label="Cancel"
									type="button"
									onClick={closeClearCheck}
									style={{marginLeft: "8px", backgroundColor: "#997570"}}
								/>
								<Button label="Clear Asset"
									type="button"
									onClick={() => {
										form.mutators.clearAsset();
										closeClearCheck();
									}}
									disabled={clearCheckText !== "CLEAR"}
									style={{marginLeft: "8px"}}
								/>
							</div>
						)}
						<div className='submit-footer'>
							<Button type="submit"
								label="Submit"
								disabled={hasSubmitted} />
							{hasSubmitted && (
								<ProgressSpinner
									style={spinnerStyle}
									strokeWidth="1"
									animationDuration="1s"
								/>
							)}
						</div>
					</form>
				);
			}}
		/></div>
	);
}