import axios from 'axios';
import { useMsal } from "@azure/msal-react";

const clientId = process.env.REACT_APP_CLIENT_ID;

const useAxiosInstance = () => {

    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const getToken = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [`${clientId}/.default`],//client id of the application registration
            account: activeAccount
        });
        return response.accessToken;
    }
    axiosInstance.interceptors.request.use(request => {

        const token = getToken();
        const accessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : token;
        if (accessToken) {
            request.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return request;
    }, error => {
        return Promise.reject(error);
    });

    // Response interceptor
    axiosInstance.interceptors.response.use(
        response => response, // Directly return successful responses.
        async error => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
                try {
                    // Make a request to your auth server to refresh the token.
                    const token = await getToken();
                    // Store the new access and refresh tokens.
                    localStorage.setItem('accessToken', token);

                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    return axiosInstance(originalRequest); // Retry the original request with the new access token.
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    localStorage.removeItem('accessToken');
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error); // For all other errors, return the error as is.
        }
    );

    return axiosInstance;
};

export default useAxiosInstance;