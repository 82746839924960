import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext.js';
import format from "date-fns-tz/format";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { PortalCardForm } from "../forms/PortalCardForm.js";
import { ImageDataUtil } from '../utilities/ImageDataUtil';
import { booleanTemplate } from '../utilities/ColumnUtil.js';
import { DropdownFilterTemplate } from '../utilities/FilterUtil.js';

const boolOpts = [
	{ label: "True", value: true },
	{ label: "False", value: false }
]
const tagLoadErrMsg = "Failed to retrieve list of tags for filtering";
const cardLoadErrMsg = "Failed to retrieve list of portal card data";
const cardSaveErrorMsg = "Failed to save submitted card data";
const cardDelErrorMsg = "Failed to delete portal card entry";

export const PortalCrud = () => {
	const [data, setData] = useState([]);
	const [tagOpts, setTagOpts] = useState([]);
	const [selectedEntry, setSelectedEntry] = useState(null);
	const [deleteEntry, setDeleteEntry] = useState(null);
	const [inProgress, setInProgress] = useState(false);

	const user = useContext(UserContext);

	useEffect(() => {
		user.apiCall("portalcards")
			.then((res) => {
				setData(res.data);
			})
			.catch(() => {
				user.showErrorToast(cardLoadErrMsg, true);
			});
		user.apiCall("portaltags")
			.then((res) => {
				setTagOpts(res.data);
			})
			.catch(() => {
				user.showErrorToast(tagLoadErrMsg, true);
			});
	}, [])

	const handleSubmit = (formData) => {
		const toSubmit = new FormData();
		
		let activeImgs = [];
		activeImgs.push("imgUrl1");

		if(formData.imgUrl2 !== null && formData.imgUrl2 !== undefined) {
			activeImgs.push("imgUrl2");
		}
		if(formData.imgUrl3 !== null && formData.imgUrl3 !== undefined) {
			activeImgs.push("imgUrl3");
		}
		if(formData.imgUrl4 !== null && formData.imgUrl4 !== undefined) {
			activeImgs.push("imgUrl4");
		}
		activeImgs.forEach((val) => {
			if(formData[val].dataUrl !== null && formData[val].dataUrl !== undefined) {
				const imageData = ImageDataUtil.dataUrlToFile(formData[val].dataUrl);
				toSubmit.append(`${val}`, imageData);
			}
			else {
				toSubmit.append(`${val}`, formData[val].link);
			}
		});

		try {
			delete formData.imgUrl1;
			delete formData.imgUrl2;
			delete formData.imgUrl3;
			delete formData.imgUrl4;
		}
		catch(ex) {
			console.log("Images deleted");
		}

		toSubmit.append("tags", formData.tags.join("~"));
		delete formData.tags;

		Object.keys(formData).forEach((val) => {
			toSubmit.append(val, formData[val]);
		});
		const callType = (formData.rowKey !== null && formData.rowKey !== undefined) ? "PUT" : "POST";
		user.apiCall("portalcard", toSubmit, callType)
			.then((res) => updateData(res.data))
			.catch(() => user.showErrorToast(cardSaveErrorMsg));
		setSelectedEntry(null);
	}

	const updateData = (newData) => {
		setData((prev) => {
			const updateIdx = prev.findIndex((val) => val.rowKey === newData.rowKey);
			if(updateIdx === -1) {
				prev.push(newData);
			}
			else {
				prev[updateIdx] = newData;
			}
			return [...prev];
		})
	}

	const handleEntryDelete = (deleteData) => {
		setInProgress(true);
		user.apiCall(`portalcard/${deleteData.rowKey}`, null, "DELETE")
			.then(() => {
				setData((prev) => {
					const updateIdx = prev.findIndex((val) => val.rowKey === deleteData.rowKey);
					prev.splice(updateIdx, 1);
					return prev;
				});
				setDeleteEntry(null);
			})
			.catch(() => user.showErrorToast(cardDelErrorMsg))
			.finally(() => setInProgress(false));
	}

	const actionHeader = () => (
		<Button icon="pi pi-plus"
			className="p-button-raised p-button-text new"
			aria-label="Create new portal card"
			title="Create new portal card"
			onClick={() => {setSelectedEntry({showOnDev  : window.location.hostname == "dev-admin.acaadigital.com", showOnProd : window.location.hostname == "admin.acaadigital.com"})}}
		>
			New
		</Button>
	);

	const actionTemplate = (rowData) => (
		<>
			<Button icon="pi pi-pencil"
				className="p-button-raised p-button-text edit"
				aria-label="Edit portal card"
				title="Edit portal card"
				onClick={() => setSelectedEntry(rowData)}
			/>
			<Button icon="pi pi-trash"
				className="p-button-raised p-button-text delete"
				aria-label="Delete portal card"
				title="Delete portal card"
				onClick={() => setDeleteEntry(rowData)}
			/>
		</>
	);

	const tagBodyTemplate = (rowData) => (
		<>
			{rowData.tags.map((val, idx) => {
				const classObj = idx > 0 ? {className: "line"} : {}
				return (
					<div key={idx} {...classObj}>
						{val}
					</div>
				);
			})}
		</>
	)

	const imgBodyTemplate = (rowData) => (
		<img src={rowData.imgUrl1} aria-label="portal app thumbnail" />
	)

	const dateOnlyTemplate = (rowData, field) => (
		<>{format(new Date(rowData[field]), "MM/dd/yyyy")}</>
	)

	return (
		<div id="Portal">
			<h1>Portal Cards List</h1>
			<DataTable value={data}
				dataKey="rowKey"
				rows={10}
				rowsPerPageOptions={[10, 25, 50]}
				sortField="appTitle"
				sortOrder="1"
				filterDisplay="row"
				paginator
			>
				<Column field="appTitle"
					header="Title"
					className="title"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="appDescShort"
					header="Short Desc"
					showFilterMenu={false}
				/>
				<Column field="imgUrl1"
					header="Thumbnail"
					body={imgBodyTemplate}
					className="thumbnail"
					showFilterMenu={false}
				/>
				<Column field="appUrl"
					header="Url"
					body={(d) => <div>{d.appUrl}</div>}
					className="url"
					showFilterMenu={false}
				/>
				<Column field="tags"
					header="Tags"
					body={tagBodyTemplate}
					filterElement={(f) => DropdownFilterTemplate(f, tagOpts, "Any")}
					className="tags"
					filterMatchMode="contains"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="refreshSchedule"
					header="Refresh"
					className="refresh"
					showFilterMenu={false}
				/>
				<Column field="showOnProd"
					header="Show Prod"
					body={(d) => booleanTemplate(d, "showOnProd")}
					filterElement={(f) => DropdownFilterTemplate(f, boolOpts, "Any")}
					className="bool"
					filterMatchMode="equals"
					showFilterMenu={false}
					filter
				/>
				<Column field="showOnDev"
					header="Show Dev"
					body={(d) => booleanTemplate(d, "showOnDev")}
					filterElement={(f) => DropdownFilterTemplate(f, boolOpts, "Any")}
					className="bool"
					filterMatchMode="equals"
					showFilterMenu={false}
					filter
				/>
				<Column field="timestamp"
					header="Entry Last Updated"
					body={(d) => dateOnlyTemplate(d, "timestamp")}
					className="timestamp"
					showFilterMenu={false}
					sortable
				/>
				<Column field=""
					header={actionHeader}
					body={actionTemplate}
					className="action-col"
				/>
			</DataTable>
			<Dialog visible={selectedEntry !== null}
				header={`${selectedEntry?.rowKey ? "Edit" : "New"} Portal Card`}
				onHide={() => setSelectedEntry(null)}
				className="portal-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<PortalCardForm values={selectedEntry} onSubmit={handleSubmit} />
			</Dialog>
			<Dialog visible={deleteEntry !== null}
				header="Delete Portal Card"
				onHide={() => setDeleteEntry(null)}
				className="portal-delete-modal"
				draggable={false}
				resizable={false}
				dismissableMask
				modal
			>
				<p>Are you sure you would like to delete the portal card for:</p>
				<div>{deleteEntry?.appTitle}</div>
				<div className='submit-footer'>
					<Button label="Confirm"
						onClick={() => handleEntryDelete(deleteEntry)}
						disabled={inProgress}
					/>
				</div>
			</Dialog>
		</div>
	);
}