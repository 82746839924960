import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../UserContext.js';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { BatteryChart } from '../components/BatteryChart';
import { DropdownFilterTemplate } from "../utilities/FilterUtil.js";
import { ExcelButton, ExportUtil } from "../utilities/ExportUtil.js";

const levelOpts = [ "HIGH", "MEDIUM", "LOW", "N/A" ];
const airportOpts = [ "PIT", "AGC" ];

const exportCols = [
	{ field: "deviceID", header: "DeviceID" },
	{ field: "building", header: "Building" },
	{ field: "level", header: "Level" },
	{ field: "concourse", header: "Concourse" },
	{ field: "batteryVoltage", header: "Voltage" },
	{ field: "batteryPercent", header: "%" },
	{ field: "sensorStatusDesc", header: "Status Description" },
	{ field: "sensorStatus", header: "Status" }
];

const filters = {
	"rowKey": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"airportIataCode": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"building": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"level": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"section": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"placeName": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"modelName": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"batteryVoltage": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"batteryPercent": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"sensorStatusDesc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"sensorStatus": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"deviceStatusDesc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"batteryLevel": { value: null, matchMode: FilterMatchMode.CONTAINS }
};
const dataLoadFail = "Device battery list failed to load.";
const statusLoadFail = "Status data failed to load."
const hasBatteryMsg = "The sensor may be a model that does not have batteries.\n" +
    "Check the model metadata to confirm.\n" +
    "Alternatively, the master data may need to update. This can take up to five minutes.";

export const Batteries = () => {
	const [batteries, setBatteries] = useState([]);
	const [statusCounts, setStatusCounts] = useState(null);
	const [displayModal, setDisplayModal] = useState(false);
	const [currentId, setCurrentId] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	const user = useContext(UserContext);
	const navigate = useNavigate();
	const dt = useRef(null);

	useEffect(() => {
		let timer = null;

		fetchBatteryList();
		fetchBatteryStatus();

		timer = setInterval(() => {
			fetchBatteryStatus()
				.then((val) => {
					// If at least one battery level has changed, pull list again
					if(val === 1) {
						fetchBatteryList();
					}
				});
		}, 5 * 60 * 1000);

		return () => {
			clearInterval(timer);
		}
	}, []);

	const fetchBatteryList = async () => {
		user.apiCall("batteries")
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast(dataLoadFail);
					return;
				}

				let tempBatteries = res.data;
				for(let battery of tempBatteries){
					if(battery.sensorStatus == 0){
						battery.sensorStatus = null;
					}
				}

				setBatteries(tempBatteries);
				setFilteredData(tempBatteries);
			})
			.catch((ex) => {
				user.showErrorToast(dataLoadFail);
				console.error(ex);
			});
	}

	const fetchBatteryStatus = async () => {
		user.apiCall("batteries/status")
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast(statusLoadFail);
					return;
				}

				let needsUpdate = 0;
				setStatusCounts((prev) => {
					if(!prev) {
						return res.data;
					}
					// Check if batteries need to be pulled again
					if(prev[0] !== res.data[0] || prev[1] !== res.data[1] || prev[2] !== res.data[2]) {
						needsUpdate = 1;
					}
					return res.data;
				});

				return needsUpdate
			})
			.catch((ex) => {
				user.showErrorToast(statusLoadFail);
				console.error(ex);
			});
	}

	const onStatusClick = (guid) => {
		setDisplayModal(true);
		setCurrentId(guid);
	}

	const onHide = () => {
		setDisplayModal(false);
		setCurrentId("");
	}

	const onClickDetails = (guid) => {
		navigate(`/devices/dashboard/${guid}`);
	}

	const exportCSV = (selectionOnly) => {
		dt.current.exportCSV({ selectionOnly });
	}

	const exportPdf = () => {
		const exportColumns = exportCols.map(col => ({ title: col.name, dataKey: col.field }));

		import("jspdf").then(jsPDF => {
			import("jspdf-autotable").then(() => {
				const doc = new jsPDF.default(0, 0);
				doc.autoTable(exportColumns, filteredData);
				doc.save("export.pdf");
			})
		})
	}

	const headerTemplate = () => (
		<>
			<div className="header-cards">
				<Card title={statusCounts ? String(statusCounts[0]) : "--"}
					subTitle="Devices in High Status"
				/>
				<Card title={statusCounts ? String(statusCounts[1]) : "--"}
					subTitle="Devices in Medium Status"
				/>
				<Card title={statusCounts ? String(statusCounts[2]) : "--"}
					subTitle="Devices in Low Status"
				/>
			</div>
			<span className="attention">Can&#39;t find the sensor you&#39;re looking for?</span>
			<Button type="button"
				className="acaa tooltip"
				icon="pi pi-question-circle"
				tooltip={hasBatteryMsg}
				tooltipOptions={{ showOnDisabled: true }}
				disabled
			/>
		</>
	);

	const footerTemplate = () => (
		<span style={{display: "flex",justifyContent: "flex-end"}}>
			<Button type="button"
				icon="pi pi-file"
				onClick={() => exportCSV(true)}
				className="mr-2"
				data-pr-tooltip="CSV"
				style={{margin: 2}}
			/>
			<ExcelButton handleExport={() => ExportUtil.exportExcel("batteries", filteredData)} />
			<Button type="button"
				icon="pi pi-file-pdf"
				onClick={exportPdf}
				className="p-button-warning mr-2"
				data-pr-tooltip="PDF"
				style={{margin: 2}}
			/>
		</span>
	);

	const statusBodyTemplate = (rowData) => {
		let statCss = "battery-";
		if (rowData.batteryPercent >= 80) {
			statCss += "high";
		}
		else if (rowData.batteryPercent >= 30) {
			statCss += "mid";
		}
		else {
			statCss += "low";
		}

		return (
			<div className="status">
				<span className={statCss} onClick={() => onStatusClick(rowData.rowKey)}>
					{rowData.batteryLevel}
				</span>
			</div>
		);
	}

	const idBodyTemplate = (rowData) => (
		<div className="id-body" onClick={() => onClickDetails(rowData.rowKey)}>
			{rowData.rowKey.toUpperCase()}
		</div>
	)

	const percentTemplate = (rowData, propName) => (
		<>{rowData[propName] ?? "--"}%</>
	)

	const telemetryBodyTemplate = (rowData) => {
		let output = "--";
		const recordDt = new Date(rowData.recordDate);

		if(recordDt.getFullYear() !== 1) {
			const diffMins = Math.floor((new Date() - recordDt) / (60 * 1000));
			const diffHrs = Math.floor(diffMins / 60);

			output = ""
			if(diffHrs > 2) {
				output += " 3+ hrs";
			}
			else {
				if(diffHrs > 0){
					output += ` ${diffHrs}hr`;
				}
				output += ` ${diffMins % 60}m`;
			}

			output += " ago";
		}

		return (<>Last seen:<br />{output}</>);
	}

	const voltageBodyTemplate = (rowData) => {
		const volt = parseFloat(rowData?.batteryVoltage);
		if(!isNaN(volt)){
			return (
				<div style={{textAlign: "center"}}>
					{volt.toFixed(2)}
				</div>
			);
		}
	}

	return (
		<div id="Batteries">
			<DataTable ref={dt}
				value={batteries}
				header={headerTemplate}
				footer={footerTemplate}
				rows={50}
				rowsPerPageOptions={[50, 100]}
				filters={filters}
				sortField="batteryPercent"
				sortOrder="1"
				filterDisplay="row"
				responsiveLayout="scroll"
				onValueChange={filteredData => setFilteredData(filteredData)}
				paginator
				showGridlines
			>
				<Column field="rowKey"
					header="DeviceID"
					body={idBodyTemplate}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="airportIataCode"
					header="Airport"
					filterElement={(d) => DropdownFilterTemplate(d, airportOpts, "")}
					style={{ maxWidth: "120px" }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="building"
					header="Building"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="level"
					header="Level"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="section"
					header="Concourse"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="placeName"
					header="Place"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="modelName"
					header="Model"
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="batteryVoltage"
					header="Voltage"
					body={voltageBodyTemplate}
					style={{ maxWidth: "100px" }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="batteryPercent"
					header="%"
					body={(rowData) => percentTemplate(rowData, "batteryPercent")}
					style={{ maxWidth: "115px" }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="sensorStatus"
					header="Sensor Status"
					style={{ maxWidth: "130px" }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="deviceStatusDesc"
					header="Device Status"
					style={{ maxWidth: "130px" }}
					showFilterMenu={false}
					filter
					sortable
				/>
				<Column field="batteryLevel"
					header="Status"
					body={statusBodyTemplate}
					filterElement={(d) => DropdownFilterTemplate(d, levelOpts, "")}
					style={{ maxWidth: "120px" }}
					showFilterMenu={false}
					filter
				/>
				<Column header="Telemetry Received"
					body={telemetryBodyTemplate}
					showFilterMenu={false}
				/>
			</DataTable>
			<Dialog header={currentId}
				visible={displayModal}
				modal={false}
				style={{ width: "50vw" }}
				onHide={onHide}
			>
				<BatteryChart key={currentId} guid={currentId} />
			</Dialog>
		</div>
	)
}
