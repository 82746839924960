import React from "react";
import * as xlsx from "xlsx";
import * as FileSaver from 'file-saver';
import { format } from "date-fns";
import { Button } from "primereact/button";

export class ExportUtil {
	static exportExcel(tableName, tableData, widthArr) {
		const worksheet = xlsx.utils.json_to_sheet(tableData);
		if(widthArr !== null && widthArr !== undefined) {
			worksheet["!cols"] = widthArr.map((w) => {
				return {"wpx": w}
			})
		}
		const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data = new Blob([excelBuffer], {
			type: EXCEL_TYPE
		});

		const fileName = `${tableName}_Export_` + format(new Date(), "yyyy-MM-dd") + EXCEL_EXTENSION;
		FileSaver.saveAs(data, fileName);
	}
}

/*
	handleExport
*/
export const ExcelButton = (props) => {
	return (
		<Button type="button"
			icon="pi pi-file-excel"
			onClick={props.handleExport}
			className="p-button-success mr-2"
			data-pr-tooltip="XLSX"
			style={{ margin: 2 }}
		/>
	)
}