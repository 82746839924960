import React from 'react';

const AppFooter = () => {

	return (
		<div className="layout-footer">
			<span className="footer-text-left">
				V 0.1
			</span>
			<span className="footer-text-right">
                ACAA Digital
			</span>
		</div>
	);
}

export default AppFooter;
