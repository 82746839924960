import React, { useEffect, useState, useContext } from 'react'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getFormErrorMessage } from '../utilities/FormHelpers'
import { UserContext } from '../UserContext.js';

/*
    props:
    values {}
    onSubmit (data)
*/
export const PlaceForm = (props) => {
	const [sectionOptions, setSectionOptions] = useState([]);
	const [placeTypeOptions, setPlaceTypeOptions] = useState([]);
	const user = useContext(UserContext)
	useEffect(() => {
		user.apiCall(`placeformoptions`)
			.then((res) => {
				setSectionOptions(res.data.sections);
				setPlaceTypeOptions(res.data.placeTypes);
			})
	}, [])

	const validate = (formData) => {
		let errors = {};

		if(!formData.placeName || formData.placeName === "") {
			errors.placeName = "Name required";
		}

		if(!formData.sectionGuid) {
			errors.sectionGuid = "Section required";
		}

		if(!formData.placeTypeGuid) {
			errors.placeTypeGuid = "Place type required";
		}

		return errors;
	}

	return (
		<Form
			onSubmit={props.onSubmit}
			validate={validate}
			initialValues={props.values}
			render={({handleSubmit}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="placeName"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="placeName"
									/>
									<label htmlFor='placeName'>Name:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="sectionGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="sectionGuid"
										options={sectionOptions}
										optionLabel="label"
									/>
									<label htmlFor='sectionGuid'>Section:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="placeTypeGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="placeTypeGuid"
										options={placeTypeOptions}
										optionLabel="label"
									/>
									<label htmlFor='placeTypeGuid'>Place Type:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}
