import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from 'primereact/card';
import { ProgressSpinner } from "primereact/progressspinner";

const loadFailure = "Table data failed to load";

export const SensorDiagnostics = () => {
    const [dataLoading, setDataLoading] = useState(false);
    const [operational, setOperational] = useState(0);
    const [malfunctioning, setMalfunctioning] = useState(0);
    const [table, setTable] = useState([]);
    const [filteredTable, setFilteredTable] = useState([]);
    const [filterType, setFilterType] = useState('all'); 
    const user = useContext(UserContext);

    useEffect(() => {
        let apiUrl = "sensorDiagnostics";
        setDataLoading(true);

        user.apiCall(apiUrl, "GET")
            .then((res) => {
                if (res.status !== 200) {
                    user.showErrorToast(loadFailure);
                    setDataLoading(false);
                    return;
                }
                console.log(res.data);
                setTable(res.data.data);
                setDataLoading(false);
            })
            .catch(() => {
                user.showErrorToast(loadFailure);
                setDataLoading(false);
            });
    }, [user]);

    useEffect(() => {
        let operationalCount = 0;
        let malfunctioningCount = 0;

        table.forEach(rowData => {
            const eventDateTime = new Date(rowData.eventDateTime);
            const currentTime = new Date();
            const timeDifferenceHours = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60));

            if (timeDifferenceHours > 3) {
                malfunctioningCount++;
            } else {
                operationalCount++;
            }
        });

        setOperational(operationalCount);
        setMalfunctioning(malfunctioningCount);

        applyFilter(filterType);
    }, [table, filterType]);

    const applyFilter = (type) => {
        if (type === 'operational') {
            setFilteredTable(table.filter(rowData => {
                const eventDateTime = new Date(rowData.eventDateTime);
                const currentTime = new Date();
                const timeDifferenceHours = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60));
                return timeDifferenceHours <= 3;
            }));
        } else if (type === 'malfunctioning') {
            setFilteredTable(table.filter(rowData => {
                const eventDateTime = new Date(rowData.eventDateTime);
                const currentTime = new Date();
                const timeDifferenceHours = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60));
                return timeDifferenceHours > 3;
            }));
        } else {
            setFilteredTable(table);
        }
    };

    const handleOperationalCardClick = () => {
        setFilterType('operational');
    };

    const handleMalfunctioningCardClick = () => {
        setFilterType('malfunctioning');
    };

    const handleAllCardClick = () => {
        setFilterType('all');
    };

    const tableHeader = () => (
        <div>
            <div className="header-cards">
                <Card 
                    title={operational ? String(operational) : "--"} 
                    subTitle="Operational Sensors" 
                    className="operational card-hover" 
                    onClick={handleOperationalCardClick} 
                />
                <Card 
                    title={malfunctioning ? String(malfunctioning) : "--"} 
                    subTitle="Malfunctioning Sensors" 
                    className="malfunctioning card-hover" 
                    onClick={handleMalfunctioningCardClick} 
                />
                <Card 
                    title={operational + malfunctioning ? String(operational + malfunctioning) : "--"} 
                    subTitle="All Sensors" 
                    className="all card-hover" 
                    onClick={handleAllCardClick} 
                />
            </div>
        </div>
    );

    const statusBodyTemplate = (rowData) => {
        let statCss = "";
        let ago = "";
        const eventDateTime = new Date(rowData.eventDateTime);
        const currentTime = new Date();
        const timeDifferenceHours = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60));
    
        if (timeDifferenceHours > 3) {
            statCss = "stagnate";
        } else {
            statCss = "normal";
        }
    
        if (timeDifferenceHours > 23) {
            const timeDifferenceDays = Math.floor((currentTime - eventDateTime) / (1000 * 60 * 60 * 24));
            ago = timeDifferenceDays + (timeDifferenceDays > 1 ? " Days ago" : " Day ago");
        } else if (timeDifferenceHours >= 1 && timeDifferenceHours < 24) {
            ago = timeDifferenceHours + (timeDifferenceHours > 1 ? " Hours ago" : " Hour ago");
        } else {
            const timeDifferenceMinutes = Math.floor((currentTime - eventDateTime) / (1000 * 60)); 
            ago = timeDifferenceMinutes + (timeDifferenceMinutes > 1 ? " Minutes ago" : " Minute ago");
        }
    
        return (
            <div className="statusTable">
                <span className={statCss}>
                    {ago}
                </span>
            </div>
        );
    }

    return (
        <div id="SensorDiagnostics" className="card">
            {dataLoading ? (
                <ProgressSpinner />
            ) : (
                <DataTable value={filteredTable}
                    dataKey="rowKey"
                    size="small"
                    header={tableHeader}
                    filterDisplay="row"
                    rows={18}
                    sortField="eventDateTime" 
                    sortOrder={1}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Sensors"
                    responsiveLayout="scroll"
                    paginator
                    showGridlines
                >
                    <Column field="deviceID" header="Device ID" showFilterMenu={false} filter sortable />
                    <Column field="buildingName" header="Building" showFilterMenu={false} filter sortable />
                    <Column field="levelName" header="Level" showFilterMenu={false} filter sortable />
                    <Column field="modelName" header="Model" showFilterMenu={false} filter sortable />
                    <Column field="modelTypeName" header="Model Type" showFilterMenu={false} filter sortable />
                    <Column field="placeName" header="Place" showFilterMenu={false} filter sortable />
                    <Column field="placeTypeCatName" header="Place Type Cat" showFilterMenu={false} filter sortable />
                    <Column field="placeTypeName" header="Place Type" showFilterMenu={false} filter sortable />
                    <Column field="sectionName" header="Section" showFilterMenu={false} filter sortable />
                    <Column field="eventDateTime" header="Last Packet Timestamp" showFilterMenu={false} sortable body={statusBodyTemplate} />
                </DataTable>
            )}
        </div>
    );
};