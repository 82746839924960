import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext.js';
import { Form, Field } from 'react-final-form';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CascadeSelect } from 'primereact/cascadeselect';
import { getFormErrorMessage } from '../utilities/FormHelpers';

/*
    values: {}
    onSubmit: (data) => {}
*/
export const NewSensorForm = (props) => {
	const [modelOptions, setModelOptions] = useState([]);
	const [sourceOptions, setSourceOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [sensorCategoryOptions, setSensorCategoryOptions] = useState([]);

	const user = useContext(UserContext);

	useEffect(() => {
		user.apiCall(`newsensorformoptions`)
			.then((res) => {
				if(res.status !== 200) {
					//displayToastError();
					return;
				}

				setLocationOptions(res.data.locations);
				setSensorCategoryOptions(res.data.sensorCategories);
				setSourceOptions(res.data.sources);
				setModelOptions(res.data.models);
			})
			.catch(() => {
				//displayToastError()
			});
	},[])

	const validate = (formData) => {
		let errors = {};

		if(!formData.sensorUid || formData.sensorUid === "") {
			errors.sensorUid = "Sensor UID required";
		}
		if(!formData.modelGuid) {
			errors.modelGuid = "Model required";
		}
		if(!formData.sourceGuid) {
			errors.sourceGuid = "Source required";
		}
		if(!formData.place) {
			errors.place = "Place required";
		}
		if(!formData.sensorCatGuid) {
			errors.sensorCatGuid = "Sensor category required";
		}

		return errors;
	}

	const handleSubmit = (data) => {
		data.placeGuid = data.place.value;
		props.onSubmit(data);
	}

	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			initialValues={props.values}
			render={({form, handleSubmit, values}) => (
				<form onSubmit={handleSubmit} className="master-form">
					<Field name="sensorUid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<InputText {...input}
										id="sensorUid"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='sensorUid'>Sensor UID:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="modelGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="modelGuid"
										options={modelOptions}
										optionLabel="label"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='modelGuid'>Model:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="sourceGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="sourceGuid"
										options={sourceOptions}
										optionLabel="label"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='sourceGuid'>Source:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="place"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<CascadeSelect {...input}
										id="place"
										value={input.value}
										options={locationOptions}
										optionGroupChildren={['buildings', 'levels', 'sections', 'places']}
										optionGroupLabel="name"
										optionLabel="label"
										style={{minWidth: '10rem'}}
										onChange={(e) => input.onChange(e.value)}
									/>
									<label htmlFor='place'>Place:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<Field name="sensorCatGuid"
						render={({ input, meta }) => (
							<div>
								<span className="p-float-label">
									<Dropdown {...input}
										id="sensorCatGuid"
										options={sensorCategoryOptions}
										optionLabel="label"
										style={{minWidth: '10rem'}}
									/>
									<label htmlFor='sensorCatGuid'>Sensor Category:&nbsp;</label>
								</span>
								{getFormErrorMessage(meta)}
							</div>
						)}/>
					<div className='submit-footer'>
						<Button type="submit" label="Submit" />
					</div>
				</form>
			)}
		/>
	);
}