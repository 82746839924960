import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import AppSettings from '../appsettings.json';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxToken = AppSettings.MapboxToken;
mapboxgl.accessToken = mapboxToken;

const defaultZoom = 15;
const defaultCenterPit = [-80.2463, 40.4955];
const centerAgc = [-79.9298, 40.3539];

/*
	mapRef
	startZoom
	startCenter
	showJumpers
	circles: [{ mid: [], mi, km }, ...]
	iconList: [{ location, name }, ...]
*/
export const AcaaMap = (props) => {
	const [mapSwitch, setMapSwitch] = useState(false);
	const [secretCount, setSecretCount] = useState(0);
	const [secretCoords, setSecretCoords] = useState(defaultCenterPit);

	const mapContainer = useRef(null);


	const resizeDebounce = (func, wait) => {
		let timeout;
		return function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	}


	if (document.getElementsByClassName('layout-main')[0]) {
		const debouncedResizeHandler = resizeDebounce(() => {
			if (props.mapRef.current) {
				props.mapRef.current.resize();
			}
		}, 100);

		const resizeObserver = new ResizeObserver(debouncedResizeHandler);
		resizeObserver.observe(document.getElementsByClassName('layout-main')[0]);
	}

	useEffect(() => {
		if (props.mapRef.current) {
			return;
		}

		props.mapRef.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/light-v10',
			center: props.startCenter ?? defaultCenterPit,
			zoom: props.startZoom ?? defaultZoom
		});

		props.mapRef.current.on('move', () => {
			const lngMove = props.mapRef.current.getCenter().lng.toFixed(4);
			const latMove = props.mapRef.current.getCenter().lat.toFixed(4);
			setSecretCoords([lngMove, latMove]);
		})

		if (props.circles !== null && props.circles !== undefined && props.circles.length > 0) {
			props.circles.forEach((val) => {
				const radius = val.km ?? val.mi * 1.6;
				props.mapRef.current.on('load', () => {
					props.mapRef.current.addSource(`circle${radius}`, drawCircle(val.mid, radius));
					props.mapRef.current.addLayer({
						"id": `circle${radius}`,
						"type": "line",
						"source": `circle${radius}`,
						"layout": {},
						"paint": {
							"line-color": "#000",
							"line-width": 1,
							"line-opacity": 0.9
						}
					});
				})
			});
		}

		loadIcons();
	}, []);

	const changeMapStyle = (isSatellite) => {
		setMapSwitch(isSatellite);

		let mapStyleId = "";
		if (isSatellite) {
			mapStyleId = "satellite-v9";
		}
		else {
			mapStyleId = "light-v10";
		}

		/*
			Mapbox overwrites layers and sources when loading a new style. The block below is to
			transfer existing user-created layers and sources to the newly loaded style. Mapbox also
			loads new icons and overwrites on setting the style. The prototype only contains a function
			to list the names of the existing icons, but not the data itself in order to pass over
			user-created icons. Thus, we load the icons again.
			There is supposed to be a `.on("styledata", () => {})` for completing this activity, but
			it does not work, in any shape or form. Layers, sources, and icons cannot be unloaded then
			re-loaded again, and this listener ends up trying to load data over top of your existing
			data - even on map startup - which throws an error for each source/layer.

			https://stackoverflow.com/questions/36168658/mapbox-gl-setstyle-removes-layers
			https://github.com/mapbox/mapbox-gl-js/issues/8660
			^ Leads to link below
			https://github.com/mapbox/mapbox-gl-js/issues/4006
			^ Andrew Harvey on Feb 24, 2018 -> The below solution, with modifications

			Good luck
		*/
		let currentStyle = props.mapRef.current.getStyle();
		axios.get(`https://api.mapbox.com/styles/v1/mapbox/${mapStyleId}?access_token=${mapboxToken}`)
			.then((res) => {
				let newStyle = res.data;
				// ensure any sources from the current style are copied across to the new style
				newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources);

				const appLayers = currentStyle.layers.filter((el) => {
					// app layers are the layers to retain, and these are any layers which have a different source set
					return (el.source && el.source !== "mapbox" && el.source !== "composite");
				});
				//console.log(currentStyle.layers, newStyle.layers);

				newStyle.layers = [...newStyle.layers, ...appLayers];
				props.mapRef.current.setStyle(newStyle); // now setStyle

				loadIcons();
			});
	}

	const loadIcons = () => {
		if (props.iconList) {
			props.iconList.forEach((val) => {
				props.mapRef.current.loadImage(val.location, (error, image) => {
					if (error) {
						console.error(error)
					}
					else {
						props.mapRef.current.addImage(val.name, image);
					}
				});
			})
		}
	}

	const jumpMap = (coords, zoom) => {
		if (!coords || coords.length !== 2) {
			return;
		}

		let flyVals = {
			center: coords,
			zoom: props.startZoom ?? defaultZoom
		};
		if (zoom && typeof (parseInt(zoom)) === "number") {
			flyVals.zoom = parseInt(zoom);
		}

		props.mapRef.current.flyTo(flyVals);
	}

	// I have no idea what these static numbers are. Taken from a previous implementation
	const drawCircle = (circleMid, radiusInKm) => {
		const points = 64;

		const lat = circleMid[1];
		const lon = circleMid[0];

		let ret = [];
		const distanceX = radiusInKm / (111.320 * Math.cos(lat * Math.PI / 180));
		const distanceY = radiusInKm / 110.574;

		let theta, x, y;
		for (let i = 0; i < points; i++) {
			theta = (i / points) * (2 * Math.PI);
			x = distanceX * Math.cos(theta);
			y = distanceY * Math.sin(theta);

			ret.push([lon + x, lat + y]);
		}
		ret.push(ret[0]);

		return {
			"type": "geojson",
			"data": {
				"type": "Feature",
				"geometry": {
					"type": "Polygon",
					"coordinates": [ret]
				}
			}
		};
	};

	const secretClick = () => {
		setSecretCount((secretCount + 1) % 7);
	}

	return (
		<>
			<div id="Map" ref={mapContainer} />
			<Card id="MapBase">
				<div className="switch-text" onClick={() => secretClick()}>
					Map Style
				</div>
				<InputSwitch checked={mapSwitch} onChange={(e) => changeMapStyle(e.value)} />
				{props.showJumpers && (
					<div className="jump-btns">
						<Button label="PIT" onClick={() => jumpMap(defaultCenterPit)} />
						<Button label="AGC" onClick={() => jumpMap(centerAgc)} />
					</div>
				)}
				{secretCount === 6 && (
					<div>
						{secretCoords[0]}, {secretCoords[1]}
					</div>
				)}
			</Card>
		</>
	);
}