import React, { useState, useContext } from "react";
import { UserContext } from "../UserContext";
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { addMonths, format } from 'date-fns';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from 'primereact/api';
import { Chart } from 'primereact/chart';
import { SplitButton } from 'primereact/splitbutton';
import { utcToLocalTemplate } from "../utilities/ColumnUtil";

const dateFmtStr = "yyyy-MM-dd HH:mm:ss";
const chartOpts = { cutout: '60%' };
const baseFilters = {
	"decodedMessage_MessagePayload_RowKey": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"decodedMessage_MessagePayload_ScheduledTimeUtc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"decodedMessage_MessagePayload_EstimatedTimeUtc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"decodedMessage_MessagePayload_BlockTimeUtc": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"decodedMessage_MessagePayload_Gate": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"deviceId": { value: null, matchMode: FilterMatchMode.CONTAINS },
	"decodedMessage_MessagePayload_ServiceType": { value: null, matchMode: FilterMatchMode.EQUALS },
	"decodedMessage_MessagePayload_RowKey1": { value: null, matchMode: FilterMatchMode.CONTAINS }
}
const reprocessMsg = "The selected flight has been queued for reprocessing";
const reprocessFail = "Failed to queue flight for reprocessing";

export const FlightCompare = () => {
	const [data, setData] = useState(null);
	const [chartData, setChartData] = useState([]);
	const [dataLoading, setDataLoading] = useState(false);
	const [startDt, setStartDt] = useState(addMonths(new Date(), -1));
	const [endDt, setEndDt] = useState(new Date());

	const user = useContext(UserContext);

	const fetchData = () => {
		const startTime = format(startDt, "yyyy-MM-dd") + " 00:00:00";
		const endTime = format(endDt, "yyyy-MM-dd") + " 23:59:59";
		const startDateStr = formatInTimeZone(new Date(startTime), "Etc/UTC", dateFmtStr);
		const endDateStr = formatInTimeZone(new Date(endTime), "Etc/UTC", dateFmtStr);

		setDataLoading(true);
		user.apiCall(`flightcompare/${startDateStr}/${endDateStr}`)
			.then((res) => {
				console.log(res.data);
				setData(res.data.unmatchedEntries);
				const mCount = res.data.successCount[0].count;
				const umCount = res.data.unmatchedEntries.length;
				const newChart = {
					labels: ["Matched", "Unmatched"],
					datasets: [
						{
							data: [mCount, umCount],
							backgroundColor: [
								"rgb(50, 205, 50)",
								"rgb(210, 43, 43)"
							],
							hoverBackgroundColor: [
								"rgba(50, 205, 50, 0.7)",
								"rgba(210, 43, 43, 0.7)"
							]
						}
					]
				};
				setChartData(newChart);

				setDataLoading(false);
			})
			.catch(() => {
				user.showErrorToast("Failed to load flight compare data");
				setDataLoading(false);
			});
	}

	const reprocessFlight = (rowkey) => {
		user.apiCall(`gatemate/reprocess/${rowkey}`, {}, "POST")
			.then(() => user.showSuccessToast(reprocessMsg))
			.catch(() => user.showErrorToast(reprocessFail));
	}

	const rowkeyTemplate = (rowData) => {
		const splitBtnItems = [
			{
				label: "Reprocess",
				icon: "pi pi-refresh",
				command: () => reprocessFlight(rowData.decodedMessage_MessagePayload_RowKey)
			}
		];

		return (
			<SplitButton label={rowData.decodedMessage_MessagePayload_RowKey}
				model={splitBtnItems}
				raised
				text
			/>
		)
	}

	const headerTemplate = () => (
		<h2>Unmatched Entries</h2>
	)

	return (
		<div id="FlightCompare">
			<h1>Flight Compare</h1>
			<div className="date-selectors">
				<div>
					<label htmlFor="startdate">Start Date</label>
					<Calendar id="startdate"
						value={startDt}
						onChange={(e) => setStartDt(e.value)}
					/>
				</div>
				<div>
					<label htmlFor="enddate">End Date</label>
					<Calendar id="enddate"
						value={endDt}
						onChange={(e) => setEndDt(e.value)}
					/>
				</div>
				{!dataLoading && (
					<Button label="Fetch"
						onClick={fetchData}
					/>
				)}
				{dataLoading && (
					<ProgressSpinner />
				)}
			</div>
			{data === null && (
				<p>Select a date range and click &#34;Fetch&#34; to load the chart and table.</p>
			)}
			<Chart type="doughnut"
				data={chartData}
				options={chartOpts}
			/>
			<DataTable value={data}
				dataKey="decodedMessage_MessagePayload_RowKey"
				size="small"
				header={headerTemplate}
				filterDisplay="row"
				filters={baseFilters}
				sortField="decodedMessage_MessagePayload_ScheduledTimeUtc"
				sortOrder="0"
				sortMode="multiple"
				rows={20}
				rowsPerPageOptions={[20, 50, 100]}
				paginator
			>
				<Column field="decodedMessage_MessagePayload_RowKey"
					header="Flight RowKey"
					body={rowkeyTemplate}
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="decodedMessage_MessagePayload_ScheduledTimeUtc"
					header="Schdeuled Time"
					body={(d) => utcToLocalTemplate(d, "decodedMessage_MessagePayload_ScheduledTimeUtc")}
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="decodedMessage_MessagePayload_EstimatedTimeUtc"
					header="Estimated Time"
					body={(d) => utcToLocalTemplate(d, "decodedMessage_MessagePayload_EstimatedTimeUtc")}
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="decodedMessage_MessagePayload_BlockTimeUtc"
					header="Block Time"
					body={(d) => utcToLocalTemplate(d, "decodedMessage_MessagePayload_BlockTimeUtc")}
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="decodedMessage_MessagePayload_Gate"
					header="Gate"
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="deviceId"
					header="Device Used"
					showFilterMenu={false}
					sortable
					filter
				/>
				<Column field="decodedMessage_MessagePayload_ServiceType"
					header="Service Type"
					showFilterMenu={false}
					sortable
					filter
				/>
			</DataTable>
		</div>
	)
}